import { FC, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import { DialogProps } from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import CustomDialog from '../custom-dialog';

import { formEditNFTCaptionsSchema } from '~/config/yup';
import { getErrorText } from '~/utils/yup.util';

export interface IEditNFTNameField {
  name: string;
  nameJa: string;
}

interface EditNameDialogProps extends DialogProps {
  title: string;
  defaultName: string;
  defaultNameJa: string;
  autoFocus?: 'en' | 'ja';
  onClose: () => void;
  onEdit: (data: IEditNFTNameField) => Promise<void>;
}

export interface FormValuesImageNFT extends yup.InferType<typeof formEditNFTCaptionsSchema> {}

const EditNameDialog: FC<EditNameDialogProps> = (props) => {
  const { open, title, autoFocus, defaultName, defaultNameJa, onEdit, onClose } = props;
  const { t } = useTranslation();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleAction =
    (callback: (data: IEditNFTNameField) => Promise<void>): SubmitHandler<IEditNFTNameField> =>
    async (data) => {
      setIsSubmitting(true);
      await callback({ name: data.name.trim(), nameJa: data.nameJa.trim() });
      setIsSubmitting(false);
    };

  const {
    control,
    formState: { dirtyFields, errors },
    reset,
    handleSubmit,
  } = useForm<FormValuesImageNFT>({
    defaultValues: {
      name: '',
      nameJa: '',
    },
    resolver: yupResolver(formEditNFTCaptionsSchema),
  });

  const isDirty = !!Object.keys(dirtyFields).length;

  useEffect(() => {
    reset({
      name: defaultName,
      nameJa: defaultNameJa,
    });
  }, [open, defaultName, defaultNameJa, reset]);

  const autoFocusInput = autoFocus || 'en';

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      dialogTitle={title}
      dialogContent={
        <>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField
                required
                fullWidth
                margin="dense"
                variant="outlined"
                spellCheck={false}
                id="outlined-basic"
                disabled={isSubmitting}
                className="dialog-input-name"
                label={t('my_shop.captionEn')}
                error={!!errors.name?.message}
                autoFocus={autoFocusInput === 'en'}
                helperText={getErrorText(errors.name?.message, t)}
                {...field}
              />
            )}
          />
          <Controller
            name="nameJa"
            control={control}
            render={({ field }) => (
              <TextField
                required
                fullWidth
                margin="normal"
                variant="outlined"
                spellCheck={false}
                id="outlined-basic"
                disabled={isSubmitting}
                className="dialog-input-name"
                label={t('my_shop.captionJa')}
                error={!!errors.nameJa?.message}
                autoFocus={autoFocusInput === 'ja'}
                helperText={getErrorText(errors.nameJa?.message, t)}
                {...field}
              />
            )}
          />
        </>
      }
      actions={[
        <Button variant="outlined" color="primary" onClick={onClose} disabled={isSubmitting}>
          {t('cancel')}
        </Button>,
        <Button
          variant="contained"
          disabled={!isDirty || isSubmitting}
          endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
          onClick={handleSubmit(handleAction(onEdit!))}
        >
          {t('save')}
        </Button>,
      ]}
    />
  );
};

export default EditNameDialog;
