import { FC, MouseEventHandler, useCallback, useState } from 'react';

import { IpfsImg } from '@gu-corp/react-ipfs-media';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import { ReactComponent as CloseIcon } from '../../../icons/images/close-icon.svg';
import { ICollectionInfo } from '../../../pages/my-shop/shop-detail/components/CollectionDetail/ShopCollectionDetail';

import DialogCloseButton from '~/components/dialog/dialog-close-button';
import EditNameDialog, { IEditNFTNameField } from '~/components/dialog/edit-nft-name-dialog';
import SquareImage, { LoadSquareImageType } from '~/components/SquareImage';
import { CURRENCY_ICONS } from '~/constants/common';
import { useShopDetail } from '~/contexts/ShopDetailWrapper';
import { INftInfo, STATUS } from '~/types/my-shop';

interface MintedNFTDialogProps {
  open: boolean;
  actions?: JSX.Element[];
  nftInfo: Partial<INftInfo>;
  collectionInfo: ICollectionInfo;
  isShopCollectionScreen?: boolean;
  onClose: () => void;
  onEditNFTName?: (data: IEditNFTNameField, nftId: string) => void;
  onOpenPriceDialog?: MouseEventHandler<SVGSVGElement> | undefined;
}

interface ImageProps {
  width: number;
  height: number;
}

const useStyles = makeStyles<{ isShopCollectionScreen?: boolean }>()((theme, { isShopCollectionScreen }) => ({
  nftDialog: {
    '.MuiPaper-root': {
      width: '100%',
      padding: '16px',
      borderRadius: '4px',
      maxWidth: 'calc(100% - 64px)',
      [theme.breakpoints.up('md')]: {
        maxWidth: 780,
      },
      '::-webkit-scrollbar': {
        display: 'none',
      },
    },
  },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    '.dialogTitle': {
      color: '#444',
      fontSize: '24px',
      fontWeight: '400',
    },
  },
  imgPreview: {
    maxWidth: '100%',
    maxHeight: '100%',
    '.MuiPaper-elevation': {
      overflowY: 'unset',
      backgroundColor: 'transparent',
    },
    '.custom-close-btn': {
      position: 'absolute',
      left: '95%',
      top: '-32px',
      right: '-10px',
      backgroundColor: 'transparent',
      color: 'white',
    },
    '.preview-image': {
      maxWidth: '100%',
      maxHeight: 'calc(100vh - 64px)',
    },
  },
  closeButton: {
    paddingTop: '6px',
  },
  content: {
    gap: '16px',
    display: 'flex',
    paddingBottom: 28,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    '.subtitle2': {
      fontWeight: 500,
      fontSize: '10px',
      color: '#00000099',
      letterSpacing: '1.5px',
    },
  },
  leftContent: {
    width: '100%',
    margin: '0 auto',
    maxWidth: '324px',
  },
  rightContent: {
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  wrapperImage: {
    width: '100%',
    maxWidth: '324px',
    position: 'relative',
    aspectRatio: '1 / 1',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  boxContent: {
    padding: '12px 16px',
    width: '100%',
    wordBreak: 'break-all',
  },
  action: {
    padding: '16px',
    justifyContent: 'end',
  },
  priceBox: {
    display: 'flex',
    justifyContent: 'space-between',
    '.MuiSvgIcon-root': {
      width: '24px',
      height: '24px',
      cursor: 'pointer',
      marginLeft: '8px',
    },
  },
}));

const MintedNFTDialog: FC<MintedNFTDialogProps> = ({
  open,
  nftInfo,
  actions,
  isShopCollectionScreen = false,
  onClose,
  onEditNFTName,
  onOpenPriceDialog,
}) => {
  const { data: myShop } = useShopDetail();

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { classes } = useStyles({ isShopCollectionScreen });

  const [imageSize, setImageSize] = useState<ImageProps>();
  const [editingLang, setEditingLang] = useState<'en' | 'ja'>('en');
  const [openImgPreview, setOpenImgPreview] = useState<boolean>(false);
  const [openEditNFTNameDialog, setOpenEditNFTNameDialog] = useState<boolean>(false);

  const nftCaption = nftInfo?.name || nftInfo?.metadataContent?.name || '-';
  const nftCaptionJa = nftInfo?.nameJa || nftInfo?.metadataContent?.name || '-';

  const notSoldOut = nftInfo?.status !== STATUS.SOLD_OUT;

  // Handle Edit Collection Name Dialog
  const onOpenEditNFTName = (value: 'en' | 'ja') => () => {
    setEditingLang(value);
    setOpenEditNFTNameDialog(true);
  };
  const onCloseEditCollectionName = () => {
    setOpenEditNFTNameDialog(false);
  };

  const onCopyAddress = useCallback(() => {
    enqueueSnackbar(t('copied'), {
      variant: 'info',
    });
  }, [enqueueSnackbar, t]);

  const onOpenImgPreview = () => {
    setOpenImgPreview(true);
  };

  const onCloseImgPreview = () => {
    setOpenImgPreview(false);
  };

  const handleEditNFTName = async (data: IEditNFTNameField) => {
    if (onEditNFTName) {
      await onEditNFTName(data, nftInfo.uuid!);
      setOpenEditNFTNameDialog(false);
    }
  };

  const handleLoadImg = (e: LoadSquareImageType) => {
    setImageSize({ width: e?.currentTarget?.naturalWidth, height: e.currentTarget.naturalHeight });
  };

  return (
    <Dialog onClose={onClose} open={open} scroll="body" className={classes.nftDialog}>
      <Box className={classes.headerWrapper}>
        <Typography className="dialogTitle">{t('nft_detail')}</Typography>
        <DialogCloseButton className={classes.closeButton} onClick={onClose} data-testid="close-button">
          <CloseIcon width={24} />
        </DialogCloseButton>
      </Box>
      <Divider sx={{ margin: '16px 0' }} />
      <Box className={classes.content}>
        <Box className={classes.leftContent}>
          <Box className={classes.wrapperImage}>
            <SquareImage
              isNFT
              src={nftInfo?.metadataContent?.image || ''}
              onClick={onOpenImgPreview}
              onLoad={handleLoadImg}
            />
          </Box>
        </Box>
        <Box className={classes.rightContent}>
          <Box className={classes.boxContent}>
            <Typography className="subtitle2">{t('my_shop.nft_name')}</Typography>
            <Box className={classes.priceBox}>
              <Tooltip
                title={nftInfo?.metadataContent?.name}
                placement="bottom-start"
                PopperProps={{
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, -10],
                      },
                    },
                  ],
                }}
              >
                <Typography className="contentDetail">{nftInfo?.metadataContent?.name}</Typography>
              </Tooltip>
            </Box>
          </Box>
          <Divider />
          {isShopCollectionScreen && (
            <>
              <Box className={classes.boxContent}>
                <Typography className="subtitle2">{t('my_shop.nft_caption_en')}</Typography>
                <Box className={classes.priceBox}>
                  <Tooltip
                    title={nftCaption}
                    placement="bottom-start"
                    PopperProps={{
                      modifiers: [
                        {
                          name: 'offset',
                          options: {
                            offset: [0, -10],
                          },
                        },
                      ],
                    }}
                  >
                    <Typography className="contentDetail">{nftCaption}</Typography>
                  </Tooltip>
                  {isShopCollectionScreen && notSoldOut && (
                    <ModeEditOutlineOutlinedIcon onClick={onOpenEditNFTName('en')} />
                  )}
                </Box>
              </Box>
              <Divider />
            </>
          )}
          {isShopCollectionScreen && (
            <>
              <Box className={classes.boxContent}>
                <Typography className="subtitle2">{t('my_shop.nft_caption_ja')}</Typography>
                <Box className={classes.priceBox}>
                  <Tooltip
                    title={nftCaptionJa}
                    placement="bottom-start"
                    PopperProps={{
                      modifiers: [
                        {
                          name: 'offset',
                          options: {
                            offset: [0, -10],
                          },
                        },
                      ],
                    }}
                  >
                    <Typography className="contentDetail">{nftCaptionJa}</Typography>
                  </Tooltip>
                  {isShopCollectionScreen && notSoldOut && (
                    <ModeEditOutlineOutlinedIcon onClick={onOpenEditNFTName('ja')} />
                  )}
                </Box>
              </Box>
              <Divider />
            </>
          )}
          {isShopCollectionScreen && (
            <>
              <Box className={classes.boxContent}>
                <Typography className="subtitle2">{t('price')}</Typography>
                <Box className={classes.priceBox}>
                  {!!nftInfo?.price ? (
                    <Typography className="contentDetail">
                      {`${CURRENCY_ICONS[myShop?.paymentMethod?.baseCurrency!]} ${nftInfo?.price?.toLocaleString(
                        'en-EN'
                      )}`}
                    </Typography>
                  ) : nftInfo?.price === 0 ? (
                    <Typography color="green" className="contentDetail">
                      {t('free')}
                    </Typography>
                  ) : (
                    <Typography color="red" className="contentDetail">
                      {t('no_price_set')}
                    </Typography>
                  )}
                  {notSoldOut && <ModeEditOutlineOutlinedIcon onClick={onOpenPriceDialog} />}
                </Box>
              </Box>
              <Divider />
            </>
          )}
          <Box className={classes.boxContent}>
            <Typography className="subtitle2">{t('owner_address')}</Typography>
            <CopyToClipboard text={nftInfo?.ownerAddress!} onCopy={onCopyAddress}>
              <Tooltip title={nftInfo?.ownerAddress} placement="top" arrow>
                <Typography className="contentDetail" sx={{ textDecoration: 'none', cursor: 'pointer' }}>
                  {nftInfo?.ownerAddress}
                </Typography>
              </Tooltip>
            </CopyToClipboard>
          </Box>
          <Divider />
          <Box className={classes.boxContent}>
            <Typography className="subtitle2">{t('token_id')}</Typography>
            <Typography className="contentDetail">{nftInfo?.tokenId}</Typography>
          </Box>
          <Divider />
          <Box className={classes.boxContent}>
            <Typography className="subtitle2">{t('token_url')}</Typography>
            <CopyToClipboard text={nftInfo?.metadataUrl!} onCopy={onCopyAddress}>
              <Tooltip title={nftInfo?.metadataUrl} placement="top" arrow>
                <Typography className="contentDetail" sx={{ textDecoration: 'none', cursor: 'pointer' }}>
                  {nftInfo?.metadataUrl}
                </Typography>
              </Tooltip>
            </CopyToClipboard>
          </Box>
          <Divider />
          <Box className={classes.boxContent}>
            <Typography className="subtitle2">{t('image_size')}</Typography>
            <Typography className="contentDetail">{`${imageSize?.width || '_'}*${
              imageSize?.height || '_'
            }`}</Typography>
          </Box>
          <Divider />
          <Box className={classes.boxContent}>
            <Typography className="subtitle2">{t('created_at')}</Typography>
            <Typography className="contentDetail">
              {moment(nftInfo?.createdAt).format(t('date_time_format')) || '-'}
            </Typography>
          </Box>
          <Divider />
          {!!nftInfo?.metadataContent?.description && (
            <>
              <Box className={classes.boxContent}>
                <Typography className="subtitle2">{t('description')}</Typography>
                <Tooltip title={nftInfo?.metadataContent?.description} placement="top" arrow>
                  <Typography className="contentDetail">{nftInfo?.metadataContent?.description}</Typography>
                </Tooltip>
              </Box>
              <Divider />
            </>
          )}
        </Box>
      </Box>
      {actions || []}
      <EditNameDialog
        autoFocus={editingLang}
        defaultName={nftCaption}
        open={openEditNFTNameDialog}
        defaultNameJa={nftCaptionJa}
        title={t('my_shop.edit_nft_name')}
        onEdit={handleEditNFTName}
        onClose={onCloseEditCollectionName}
      />
      <Dialog onClose={onCloseImgPreview} open={openImgPreview} scroll="body" className={classes.imgPreview}>
        <IconButton onClick={onCloseImgPreview} data-testid="close-button" className="custom-close-btn">
          <CloseIcon />
        </IconButton>
        <IpfsImg crossOrigin="anonymous" url={nftInfo?.metadataContent?.image || ''} className="preview-image" />
      </Dialog>
    </Dialog>
  );
};

export default MintedNFTDialog;
