import React, { useCallback, useState } from 'react';

import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles } from 'tss-react/mui';

import HeaderBar from './header-bar';
import SideBar from './side-bar';

import AccessDenied from '~/components/access-denied';
import { DRAWER_WIDTH, DRAWER_WIDTH_SM, HEADER_HEIGHT } from '~/constants/layout';
import { useAccount } from '~/hooks/with-account';
import { StyledComponentProps } from '~/types/material-ui';

const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: theme.colors.white,
  },
  rightSide: {
    position: 'relative',
    marginLeft: DRAWER_WIDTH,
    backgroundColor: '#F8F8F8',
    transition: 'all 250ms ease 0s',
    [theme.breakpoints.down('md')]: {
      marginLeft: DRAWER_WIDTH_SM,
    },
  },
  rightSideTemporarySideBar: {
    marginLeft: 0,
  },
  contentWrapper: {
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    height: `calc(100vh - ${HEADER_HEIGHT}px)`,
  },
  content: {
    flex: 1,
    display: 'flex',
    gap: theme.spacing(2),
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '24px 16px',
    [theme.breakpoints.up('md')]: {
      padding: '24px',
    },
  },
}));

interface Props extends StyledComponentProps<typeof useStyles> {}

const UserLayout: React.FC<React.PropsWithChildren<Props>> = ({ children, ...props }) => {
  const { isApproved } = useAccount();
  const { classes, cx, theme } = useStyles(undefined, { props: { classes: props.classes } });
  const isTemporarySideBar = useMediaQuery(theme.breakpoints.down('md'));

  const [openDrawer, setOpenDrawer] = useState(false);

  const onToggleDrawer = useCallback(() => {
    setOpenDrawer((open) => !open);
  }, []);

  const onCloseDrawer = useCallback(() => {
    setOpenDrawer(false);
  }, []);

  return (
    <div className={classes.root} data-testid="layout">
      <HeaderBar isTemporarySideBar={isTemporarySideBar} onToggleDrawer={onToggleDrawer} />
      <SideBar isTemporarySideBar={isTemporarySideBar} openDrawer={openDrawer} onCloseDrawer={onCloseDrawer} />
      {isApproved ? (
        <>
          <div className={cx(classes.rightSide, isTemporarySideBar && classes.rightSideTemporarySideBar)}>
            <div className={classes.contentWrapper}>
              <div className={classes.content}>{children}</div>
            </div>
          </div>
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
};

export default UserLayout;
