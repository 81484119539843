import { useMemo, useRef, useState } from 'react';

import { MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import MonthlyTransactions from './MonthlyTransactions';
import UpcomingInvoice from './UpcomingInvoice';

import CustomCardTable from '~/components/custom-card-table';
import HeaderAction from '~/components/custom-card-table/HeaderAction';
import { INVOICE_ITEM_NAME } from '~/constants/common';
import { InvoiceType } from '~/enum/common';
import { useGetPlanLazyQuery, useGetUpcomingInvoiceQuery, useListAllInvoicesLazyQuery } from '~/graphql/member/types';
import useExportCSV from '~/hooks/useExportCSV';
import { useNotify } from '~/hooks/useNotify';
import { IPageModel, ITransactionHistoryRef } from '~/interfaces/billing';

const useStyles = makeStyles()(() => ({
  wrapper: {
    width: '100%',
    '.wrapperDatePicker': {
      gap: '8px',
      display: 'flex',
      marginBottom: '8px',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
  },
  wrapperContent: {
    '.MuiDataGrid-root .MuiDataGrid-footerContainer .MuiTablePagination-displayedRows': {
      display: 'none',
    },
  },
}));

const initPageModel = {
  page: 0,
  pageParams: {},
};

const TransactionHistory = () => {
  const { classes } = useStyles();
  const { showError } = useNotify();
  const { t, i18n } = useTranslation();
  const { exportToCSV } = useExportCSV();

  const [pageModel, setPageModel] = useState<IPageModel>(initPageModel);
  const [monthlyFilter, setMonthlyFilter] = useState<string>(moment().format('YYYY/MM'));

  const ref = useRef<ITransactionHistoryRef>(null);

  const { data: upcomingInvoiceRes } = useGetUpcomingInvoiceQuery({
    fetchPolicy: 'cache-and-network',
  });
  const [getPlan] = useGetPlanLazyQuery({
    fetchPolicy: 'cache-and-network',
  });
  const [listAllInvoices] = useListAllInvoicesLazyQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        createdGte: moment(monthlyFilter).toDate(),
        createdLte: moment(monthlyFilter).endOf('month').toDate(),
      },
    },
  });

  const upcomingInvoice = upcomingInvoiceRes?.getUpcomingInvoice;

  const monthOptions = useMemo(() => {
    const result = !upcomingInvoice?.length
      ? []
      : [
          <MenuItem key="upcoming-invoice" value="upcoming-invoice">
            {t('settings.billing.upcoming_invoice')}
          </MenuItem>,
        ];
    const monthlyList = Array.from({ length: 13 }, (_, i) => {
      const value = moment().subtract(i, 'months').format('YYYY/MM');
      return (
        <MenuItem key={value} value={value}>
          {value}
        </MenuItem>
      );
    });
    return result.concat(monthlyList);
  }, [upcomingInvoice, t]);

  const handleSelect = (event: SelectChangeEvent<string>) => {
    setPageModel(initPageModel);
    setMonthlyFilter(event.target.value);
  };

  const handleExportMonth = async () => {
    try {
      const allInvoicesRes = await listAllInvoices();
      const allInvoices = allInvoicesRes.data?.listAllInvoices || [];
      const exportData = [];
      for (let invoice of allInvoices) {
        const metadata = invoice.metadata;
        const type = metadata?.type as InvoiceType;
        let name = t(INVOICE_ITEM_NAME[type]);

        if (type === InvoiceType.PurchasePlan && !!metadata?.uuid) {
          const planRes = await getPlan({
            variables: {
              uuid: metadata.uuid,
            },
          });
          const planName = planRes.data?.getPlan.planName;
          if (!!planName) {
            moment.locale(i18n.language);
            const nextMonth = moment().add(1, 'month').format('MMMM');
            const isRenew = invoice.billingReason === 'subscription_cycle';

            name = isRenew
              ? t('settings.billing.renew_plan_desc', { planName, month: nextMonth })
              : t('settings.billing.register_plan', { planName });

            moment.locale('en');
          }
        }
        exportData.push({
          'Invoice Number': invoice.number,
          Description: name,
          Status: invoice.status,
          Currency: invoice.currency,
          Total: invoice.total,
          Tax: invoice.tax,
          Date: moment(invoice.createdAt).format(t('date_time_format')),
        });
      }
      exportToCSV(exportData, `all-invoices-${moment(monthlyFilter).format('MM-YYYY')}.csv`);
    } catch (err) {
      showError(err);
    }
  };

  const handleExportCurrentPage = () => {
    if (ref.current) {
      ref.current.handleExportCurrentPage();
    }
  };

  return (
    <CustomCardTable
      cardTitle={t('settings.billing.transaction_history')}
      headerAction={
        <Box gap="8px" display="flex" alignItems="center">
          <Typography>{t('month')}:</Typography>
          <Select id="selector" sx={{ height: 38 }} value={monthlyFilter} onChange={handleSelect}>
            {monthOptions}
          </Select>
          <HeaderAction
            menus={
              monthlyFilter === 'upcoming-invoice'
                ? [{ title: t('settings.billing.export_upcoming_invoice'), onClick: handleExportCurrentPage }]
                : [
                    { title: t('settings.billing.export_current_page'), onClick: handleExportCurrentPage },
                    { title: t('settings.billing.export_month'), onClick: handleExportMonth },
                  ]
            }
          />
        </Box>
      }
      cardContent={
        <Box className={classes.wrapperContent}>
          {monthlyFilter === 'upcoming-invoice' ? (
            <UpcomingInvoice ref={ref} upcomingInvoices={upcomingInvoice!} />
          ) : (
            <MonthlyTransactions
              ref={ref}
              pageModel={pageModel}
              filterValue={monthlyFilter}
              onPageModel={setPageModel}
            />
          )}
        </Box>
      }
    />
  );
};

export default TransactionHistory;
