import { FC, useEffect, useRef } from 'react';

import { useSnackbar } from 'notistack';

import ScreenLoading from '../screen-loading';

import {
  Currency,
  GetCurrentPlanDocument,
  GetMeDocument,
  RecurrenceType,
  useGetPlansQuery,
  useSubscribePlanMutation,
} from '~/graphql/member/types';

const SubscribeFreePlan: FC = () => {
  const { enqueueSnackbar } = useSnackbar();

  const isCalled = useRef(false);

  const [subscribePlan] = useSubscribePlanMutation({
    refetchQueries: [GetCurrentPlanDocument, GetMeDocument],
  });
  const { data: plansRes } = useGetPlansQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      currency: Currency.Usd,
    },
  });
  const freePlan = plansRes?.getPlans?.[0];

  useEffect(() => {
    (async () => {
      try {
        if (!isCalled.current && !!freePlan) {
          isCalled.current = true;
          await subscribePlan({
            variables: {
              input: {
                uuid: freePlan.uuid,
                recurrence: RecurrenceType.Monthly,
              },
            },
          });
        }
      } catch (e: any) {
        enqueueSnackbar(e.message, { variant: 'error' });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [freePlan, enqueueSnackbar]);

  return <ScreenLoading />;
};

export default SubscribeFreePlan;
