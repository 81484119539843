import dayjs from 'dayjs';
import { TFunction } from 'i18next';

import { CURRENCY_ICONS } from '~/constants/common';
import { OrderByGrid } from '~/constants/tableKeys';
import { Currency, OrderBy } from '~/graphql/member/types';
import { OrderDirection } from '~/graphql/subgraph/types';

interface SortConfig {
  key: string;
  order?: 'asc' | 'desc' | null;
}

interface localStorageItems {}

export const multiSort = <T>(array: T[], sortConfigs: SortConfig[]): T[] => {
  return array.sort((a, b) => {
    for (const config of sortConfigs) {
      const { key, order = 'asc' } = config;

      const aValue = (a as any)[key];
      const bValue = (b as any)[key];

      if (aValue < bValue) {
        return order === 'asc' ? -1 : 1;
      } else if (aValue > bValue) {
        return order === 'asc' ? 1 : -1;
      }
    }

    return 0;
  });
};

export const isNumberic = (value: string) => {
  return /^\d+$/.test(value);
};

export const setLocalStorageItems = (items: localStorageItems) => {
  for (const [key, value] of Object.entries(items)) {
    if (value !== null && value !== undefined) {
      localStorage.setItem(key, value);
    } else {
      localStorage.removeItem(key);
    }
  }
};
export const getLocalStorage = (key: string) => {
  return localStorage.getItem(key);
};

export const verifySortKey = (keys: any, value: any, defaultValue?: any) => {
  return Object.values(keys).includes(value) ? value : defaultValue || keys.CreatedAt;
};

export const verifyOrderKey = (orderKey?: any) => {
  return orderKey === OrderBy.Asc ? OrderBy.Asc : OrderBy.Desc;
};

export const verifySubgraphOrderKey = (orderKey?: any) => {
  return orderKey === OrderDirection.Asc ? OrderDirection.Asc : OrderDirection.Desc;
};

export const convertOrderToAPI = (value?: string) => {
  return !!value ? (value === OrderDirection.Asc ? OrderByGrid.asc : OrderByGrid.desc) : undefined;
};

export const convertOrderToSubgraph = (value?: string) => {
  return !!value ? (value === OrderByGrid.asc ? OrderDirection.Asc : OrderDirection.Desc) : undefined;
};

export const fetchWithTimeout = async (url: string, timeout: number = 8000) => {
  const options: { timeout: number; signal?: AbortSignal } = { timeout };
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  options.signal = controller.signal;

  try {
    const response = await fetch(url, options);
    clearTimeout(id);
    return response;
  } catch (error: any) {
    if (error.name === 'AbortError') {
      throw new Error('Request timed out');
    }
    throw error;
  }
};

export const generateQueryString = (obj: Record<string, any>) => {
  return `?${Object.keys(obj)
    .reduce((result, key) => {
      if (![undefined, null].includes(obj[key])) {
        result.push(`${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`);
      }
      return result;
    }, [] as string[])
    .join('&')}`;
};

export const convertToDayjs = (date: Date | undefined | null) => (!!date ? dayjs(date) : undefined);

export const currencySymbol = (currency: Currency) =>
  CURRENCY_ICONS[currency.toUpperCase()] || CURRENCY_ICONS[Currency.Usd];

export const formatNumberWithCommas = (value: string) => {
  return (value || '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const priceWithSymbol = (price: number | undefined | null, currency: Currency, fixed?: number) => {
  const currencyUpperCase = currency.toUpperCase() as Currency;
  const symbol = currencySymbol(currency);
  if (typeof price !== 'number') {
    return `${symbol}0`;
  }
  const isNegative = price < 0;
  const absPrice = Math.abs(price);
  const amount =
    currencyUpperCase === Currency.Jpy
      ? absPrice.toFixed(0)
      : typeof fixed === 'number'
      ? absPrice.toFixed(Math.abs(fixed))
      : absPrice.toString();
  return (isNegative ? '-' : '') + `${symbol}${formatNumberWithCommas(amount)}`;
};

export const replaceLicenseName = (inputString: string, t: TFunction): string => {
  const keyMap: { [key: string]: string } = {
    additionalShopFee: t('license.shop', { lng: 'en' }),
    additionalUserFee: t('license.user', { lng: 'en' }),
    additionalSiteFee: t('license.member_site', { lng: 'en' }),
    additionalMemberFee: t('license.member_per_member_site', { lng: 'en' }),
  };

  const regex = new RegExp(Object.keys(keyMap).join('|'), 'g');

  return inputString.replace(regex, (matched) => keyMap[matched]);
};
