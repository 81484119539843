import { FC, PropsWithChildren } from 'react';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import CustomDialog from '../custom-dialog';

import { LicenseType, OrganizationUserRole } from '~/graphql/member/types';
import { useAccount } from '~/hooks/with-account';

interface IInsufficientLicenseDialog extends PropsWithChildren {
  open: boolean;
  type: LicenseType;
  onClose: () => void;
  onSubmit: () => void;
}

const contentDialog = {
  [LicenseType.AdditionalUserFee]: 'users_reached_limit',
  [LicenseType.AdditionalShopFee]: 'shops_reached_limit',
  [LicenseType.AdditionalMemberSiteFee]: 'member_sites_reached_limit',
  [LicenseType.AdditionalMemberFee]: 'members_per_member_site_reached_limit',
};

const InsufficientLicenseDialog: FC<IInsufficientLicenseDialog> = ({ open, type, onClose, onSubmit }) => {
  const { t } = useTranslation();
  const { selectedOrganization } = useAccount();

  const isOwner = selectedOrganization.role === OrganizationUserRole.Owner;

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      dialogTitle={t('license_limit_reached')}
      dialogContent={
        <>
          <Typography>{t(contentDialog[type])}</Typography>
          {!isOwner && (
            <Typography variant="body2" color="error" marginTop="8px">
              *{t('payment_method_required.required_contact_owner')}
            </Typography>
          )}
        </>
      }
      actions={
        isOwner
          ? [
              <Button variant="outlined" onClick={onClose}>
                {t('cancel')}
              </Button>,
              <Button variant="contained" onClick={onSubmit}>
                {t('buy_now')}
              </Button>,
            ]
          : [
              <Button variant="contained" onClick={onClose}>
                {t('cancel')}
              </Button>,
            ]
      }
    />
  );
};

export default InsufficientLicenseDialog;
