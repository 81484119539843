import { FC, PropsWithChildren } from 'react';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { HEADER_HEIGHT } from '~/constants/layout';

const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: '#F8F8F8',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    color: theme.palette.text.secondary,
  },
  toolbar: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    height: HEADER_HEIGHT,
    justifyContent: 'center',
  },
  logo: {
    color: '#3E5B72',
    height: 30,
    display: 'block',
    pointerEvents: 'none',
    fontWeight: 700,
    whiteSpace: 'nowrap',
  },
  contentWrapper: {
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    height: `calc(100vh - ${HEADER_HEIGHT}px)`,
  },
  content: {
    flex: 1,
    display: 'flex',
    gap: theme.spacing(2),
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '24px 16px',
    [theme.breakpoints.up('md')]: {
      padding: '24px',
    },
  },
}));

const PublicLayout: FC<PropsWithChildren> = ({ children }) => {
  const { classes } = useStyles();
  return (
    <div className={classes.root} data-testid="layout">
      <AppBar position="sticky" className={classes.appBar} color="inherit">
        <Toolbar className={classes.toolbar}>
          <Link to="/">
            <img className={classes.logo} src="/images/gu-logo-text.svg" alt="" />
          </Link>
        </Toolbar>
      </AppBar>
      <div className={classes.contentWrapper}>
        <div className={classes.content}>{children}</div>
      </div>
    </div>
  );
};

export default PublicLayout;
