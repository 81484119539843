import { useCallback, useState } from 'react';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { StepConnector, stepConnectorClasses, styled } from '@mui/material';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import { TFunction } from 'i18next';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Navigate, Link as RouterLink, useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import BasicSiteSetting from '../member-site-detail/components/shop-detail-tabs/BasicSiteSetting';
import MemberTerms from '../member-site-detail/components/shop-detail-tabs/MemberTerms';
import RegistrationCondition from '../member-site-detail/components/shop-detail-tabs/RegistrationCondition';

import UserLayout from '~/components/app-layout/user-layout';
import HomeBtn from '~/components/home-btn';
import LoaderCenter from '~/components/loader-center';
import { IMyShopFormWrapper, useMyShopFormWrapper } from '~/contexts/MyShopFormWrapper';
import { AppRouteEnum } from '~/enum/AppRouteEnum';
import { ListMyShopsDocument, ThemeInput, useCreateMyShopMutation, useGetInfoUsageQuery } from '~/graphql/member/types';
import { useAccount } from '~/hooks/with-account';
import { initFormEditShop } from '~/pages/edit-shop';
import { SHOP_TYPE } from '~/types/my-shop';

const useStyles = makeStyles()(() => ({
  wrapperForm: {
    width: '100%',
    marginTop: '16px',
  },
  form: {},
}));

export const steps = (
  t: TFunction,
  isSubmitting: boolean,
  handleCreateMyPage?: (data: IMyShopFormWrapper) => Promise<void>
) => {
  return [
    { stepTitle: t('member_site.basic_settings'), component: <BasicSiteSetting isCreate /> },
    { stepTitle: t('member_site.member_terms'), component: <MemberTerms isCreate /> },
    {
      stepTitle: t('member_site.registration_condition'),
      component: <RegistrationCondition isCreate isSubmitting={isSubmitting} onCreate={handleCreateMyPage} />,
    },
  ];
};

const CreateMemberSite = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { isLocked } = useAccount();
  const { step } = useMyShopFormWrapper();
  const { enqueueSnackbar } = useSnackbar();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { data: getInfoUsage, loading: loadingInfoUsage } = useGetInfoUsageQuery({
    fetchPolicy: 'cache-and-network',
  });
  const [createMyShop] = useCreateMyShopMutation({
    refetchQueries: [ListMyShopsDocument],
  });

  const infoUsage = getInfoUsage?.getInfoUsage;
  const notEnoughLicenses = (infoUsage?.numberMemberSite || 0) >= (infoUsage?.maxNumberMemberSite || 0);

  const handleCreateMyPage = useCallback(
    async (data: IMyShopFormWrapper) => {
      setIsSubmitting(true);
      try {
        const { siteSetting, shopInformation, nftActivationSettings, paymentMethod } = data;
        const newShop = await createMyShop({
          variables: {
            input: {
              shopType: SHOP_TYPE.MEMBER,
              siteSetting: {
                name: siteSetting?.siteName,
                title: siteSetting?.siteName,
                meta: siteSetting?.metaDescription,
                description: siteSetting?.metaDescription,
                theme: {
                  ...(initFormEditShop(true) as ThemeInput),
                  navi: {
                    pageTitle: siteSetting?.siteName,
                    pageTitleJa: siteSetting?.siteName,
                    description: siteSetting?.metaDescription,
                    descriptionJa: siteSetting?.metaDescription,
                  },
                },
              },
              shopInformation: {
                policy: shopInformation?.policy,
              },
              paymentMethod: {
                baseCurrency: paymentMethod?.baseCurrency,
              },
              nftActivationSettings: {
                ...nftActivationSettings,
                expireValue: nftActivationSettings?.expireValue?.toString(),
                acquisitionInformation: nftActivationSettings?.acquisitionInformation,
              },
              domain: {
                name: siteSetting?.domainName,
              },
              ogpFile: siteSetting?.ogpFile?.[0],
              logoFile: siteSetting?.logoFile?.[0],
              faviconFile: siteSetting?.faviconFile?.[0],
            },
          },
        });
        setIsSubmitting(false);
        enqueueSnackbar(t('toast_message.create_member_site_successful'), { variant: 'success' });
        navigate(AppRouteEnum.MemberSiteDetail.replace(/:id/, newShop.data?.createMyShop.uuid || ''));
      } catch (err: any) {
        enqueueSnackbar(err?.message ?? t('my_shop.message.error'), { variant: 'error' });
      }
    },
    [t, createMyShop, navigate, enqueueSnackbar]
  );

  const StyledStepConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#0367DF',
      },
    },
  }));

  if (loadingInfoUsage) {
    return (
      <UserLayout>
        <LoaderCenter />
      </UserLayout>
    );
  }

  if (notEnoughLicenses || isLocked) {
    return <Navigate to={AppRouteEnum.MemberSite} />;
  }

  return (
    <UserLayout>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
        <HomeBtn />
        <Link
          component={RouterLink}
          to={AppRouteEnum.MemberSite}
          color="text.primary"
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          {t('member_site.member_site')}
        </Link>
        <Typography color="text.secondary">{t('member_site.create_new_member_site')}</Typography>
      </Breadcrumbs>
      <Box className={classes.wrapperForm}>
        <Box margin="24px 0">
          <Stepper alternativeLabel activeStep={step} connector={<StyledStepConnector />}>
            {steps(t, isSubmitting).map((label) => (
              <Step key={label.stepTitle}>
                <StepLabel>{label.stepTitle}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        <Box className={classes.form}>{steps(t, isSubmitting, handleCreateMyPage)[step].component}</Box>
      </Box>
    </UserLayout>
  );
};

export default CreateMemberSite;
