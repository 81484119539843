import { useEffect } from 'react';

import { Box, Grid, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import PlanCard from './PlanCard';

import UserLayout from '~/components/app-layout/user-layout';
import { Currency, useGetPlansQuery } from '~/graphql/member/types';
import { useAccount } from '~/hooks/with-account';

const Plans = () => {
  const { t } = useTranslation();
  const { plan } = useAccount();
  const { data: plansRes } = useGetPlansQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      currency: Currency.Usd,
    },
  });
  const plans = plansRes?.getPlans;
  const selectedPlanUuid = plan?.uuid;

  useEffect(() => {
    document.title = t('plans.title');
  }, [t]);

  return (
    <UserLayout>
      <Stack gap="40px" width="100%" alignItems="center">
        <Box>
          <Typography variant="h3" textAlign="center">
            {t('choose_the_right_plan_for_you')}
          </Typography>
          <Typography variant="body2" textAlign="center" marginTop="8px">
            {t('plans.only_owner_can_select')}
          </Typography>
        </Box>
        <Grid container spacing={3}>
          {plans?.map((plan) => (
            <Grid item key={plan.uuid} xs={12} sm={6} lg={3}>
              <PlanCard isSelected={selectedPlanUuid === plan.uuid} plan={plan} />
            </Grid>
          ))}
        </Grid>
      </Stack>
    </UserLayout>
  );
};

export default Plans;
