import { FC, useContext, useEffect, useState } from 'react';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { JsonRpcProvider, formatEther } from 'ethers';
import { TFunction } from 'i18next';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import DialogCloseButton from '~/components/dialog/dialog-close-button';
import { MIN_DEPOSIT } from '~/constants/common';
import { usePaymentMethodRequired } from '~/contexts/PaymentMethodRequired';
import { SupportedNetworksContext, SupportedNetworksContextValue } from '~/contexts/SupportedNetworksProvider';
import {
  MyShopCollectionQueryKey,
  QueryOperator,
  useGetCanOpenShopLazyQuery,
  useGetMyShopLazyQuery,
  useListMyShopCollectionsLazyQuery,
} from '~/graphql/member/types';
import { useAccount } from '~/hooks/with-account';

interface ConditionPublishProps extends DialogProps {
  onClose: () => void;
  onPassed: (newValue: boolean) => void;
}

export enum ConditionPublishEnum {
  StripeNotEntered = 'stripeNotEntered',
  InsufficientBalance = 'insufficientBalance',
  NotAttachCollection = 'notAttachCollection',
  PricedNftButNotPayment = 'pricedNftButNotPayment',
}

const errorsList = (t: TFunction): Record<ConditionPublishEnum, { title: string; value: ConditionPublishEnum }> => ({
  [ConditionPublishEnum.StripeNotEntered]: {
    title: t('my_shop.message.fincode_or_other_information_not_entered'),
    value: ConditionPublishEnum.StripeNotEntered,
  },
  [ConditionPublishEnum.NotAttachCollection]: {
    title: t('my_shop.message.not_attach_any_collection'),
    value: ConditionPublishEnum.NotAttachCollection,
  },
  [ConditionPublishEnum.InsufficientBalance]: {
    title: t('my_shop.message.deposit_is_sufficient'),
    value: ConditionPublishEnum.InsufficientBalance,
  },
  [ConditionPublishEnum.PricedNftButNotPayment]: {
    title: t('my_shop.message.priced_nft_but_not_registered_payment_method'),
    value: ConditionPublishEnum.PricedNftButNotPayment,
  },
});

enum STATUS_CHECK {
  LOADING = 'LOADING',
  PASSED = 'PASSED',
  ERROR = 'ERROR',
}

const initCondition = {
  [ConditionPublishEnum.StripeNotEntered]: STATUS_CHECK.LOADING,
  [ConditionPublishEnum.NotAttachCollection]: STATUS_CHECK.LOADING,
  [ConditionPublishEnum.InsufficientBalance]: STATUS_CHECK.LOADING,
  [ConditionPublishEnum.PricedNftButNotPayment]: STATUS_CHECK.LOADING,
};

const ConditionPublish: FC<ConditionPublishProps> = ({ onClose, onPassed, ...others }) => {
  const { supportedNetworks } = useContext(SupportedNetworksContext) as SupportedNetworksContextValue;

  const { id } = useParams();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { show } = usePaymentMethodRequired();
  const { selectedOrganization } = useAccount();

  const [status, setStatus] = useState(initCondition);

  const [getCanOpenShop] = useGetCanOpenShopLazyQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      myShopUuid: id || '',
    },
  });
  const [getMyShop] = useGetMyShopLazyQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      myShopUuid: id || '',
    },
  });
  const [listMyShopCollections] = useListMyShopCollectionsLazyQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      where: {
        fields: [
          {
            value: [id ?? ''],
            operator: QueryOperator.Contains,
            key: MyShopCollectionQueryKey.ShopUuid,
          },
        ],
      },
    },
  });

  useEffect(() => {
    if (others.open && id) {
      (async () => {
        try {
          let isError = false;
          // Check if any collection has been attached yet
          const listMyShopCollectionRes = await listMyShopCollections();
          const myShopCollections = listMyShopCollectionRes.data?.listMyShopCollections.items;
          const myShopCollectionsLength = myShopCollections?.length || 0;
          isError = isError || !(myShopCollectionsLength > 0);
          setStatus((newState) => ({
            ...newState,
            [ConditionPublishEnum.NotAttachCollection]:
              myShopCollectionsLength > 0 ? STATUS_CHECK.PASSED : STATUS_CHECK.ERROR,
          }));

          // Check if the payment keys have been configured
          const paymentSetting = selectedOrganization.paymentSetting;
          isError = isError || !(!!paymentSetting?.publicKey && !!paymentSetting?.secretKey);
          setStatus((newState) => ({
            ...newState,
            [ConditionPublishEnum.StripeNotEntered]:
              !!paymentSetting?.publicKey && !!paymentSetting?.secretKey ? STATUS_CHECK.PASSED : STATUS_CHECK.ERROR,
          }));

          // Check if the deposit balance reaches the minimum amount
          const latestNetwork = Object.values(supportedNetworks)?.[0];
          const provider = new JsonRpcProvider(latestNetwork?.rpcUrl);
          const orgWallet = selectedOrganization.masterWalletAddress;
          let depositCondition = false;
          if (!!orgWallet) {
            const balanceBigNumber = await provider.getBalance(orgWallet);
            const balance = formatEther(balanceBigNumber);
            depositCondition = Number(balance) >= MIN_DEPOSIT;
          }
          isError = isError || !depositCondition;
          setStatus((newState) => ({
            ...newState,
            [ConditionPublishEnum.InsufficientBalance]: depositCondition ? STATUS_CHECK.PASSED : STATUS_CHECK.ERROR,
          }));

          // Check if there is a nft with a price while the payment method is not registered
          const registeredPaymentMethodRes = await getCanOpenShop();
          const registeredPaymentMethod = registeredPaymentMethodRes.data?.getCanOpenShop;
          if (!registeredPaymentMethod) {
            await show({
              description: 'payment_method_required.nft_has_price',
            });
          }
          isError = isError || !registeredPaymentMethod;
          setStatus((newState) => ({
            ...newState,
            [ConditionPublishEnum.PricedNftButNotPayment]: registeredPaymentMethod
              ? STATUS_CHECK.PASSED
              : STATUS_CHECK.ERROR,
          }));

          await getMyShop();
          if (!isError) {
            onPassed(true);
          } else {
            enqueueSnackbar(t('toast_message.open_shop_unsuccessfully'), { variant: 'error' });
          }
        } catch (err: any) {}
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [others.open]);

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    setStatus(initCondition);
  };

  const disableClose = Object.values(status).includes(STATUS_CHECK.LOADING);

  return (
    <Dialog {...others} maxWidth="sm" scroll="body" fullWidth>
      {!disableClose && (
        <DialogCloseButton onClick={handleClose} data-testid="close-button">
          <CloseIcon />
        </DialogCloseButton>
      )}
      <DialogTitle>
        <Typography variant="h4" color="inherit" component="p" className="title">
          {t('my_shop.publish_condition')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {(Object.keys(status) as ConditionPublishEnum[]).map((condition) => (
          <Box key={condition} display="flex" gap="8px" marginBottom="8px">
            {status[condition] === STATUS_CHECK.LOADING ? (
              <CircularProgress size="20px" sx={{ marginRight: '4px' }} color="primary" />
            ) : status[condition] === STATUS_CHECK.PASSED ? (
              <CheckCircleOutlineIcon color="primary" />
            ) : status[condition] === STATUS_CHECK.ERROR ? (
              <ErrorOutlineIcon color="error" />
            ) : null}
            <Typography variant="subtitle2" color={status[condition] === STATUS_CHECK.ERROR ? 'error' : 'primary'}>
              {errorsList(t)[condition].title}
            </Typography>
          </Box>
        ))}
      </DialogContent>
    </Dialog>
  );
};

export default ConditionPublish;
