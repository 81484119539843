import { FC, PropsWithChildren, useState } from 'react';

import { FetchResult, InternalRefetchQueriesInclude } from '@apollo/client';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import CustomDialog from '../custom-dialog';

import LoaderCenter from '~/components/loader-center';
import { colors } from '~/constants/colors';
import {
  BuyLicenseInput,
  BuyLicenseMutation,
  LicenseType,
  useBuyLicenseMutation,
  useEstimateFeeLicenseQuery,
} from '~/graphql/member/types';
import { useAccount } from '~/hooks/with-account';

interface ILicenseStatementDialog extends PropsWithChildren {
  open: boolean;
  license: BuyLicenseInput;
  refetchQueries?:
    | InternalRefetchQueriesInclude
    | ((result: FetchResult<BuyLicenseMutation>) => InternalRefetchQueriesInclude);
  onNext?: () => void;
  onClose: () => void;
}

const licenseNames = {
  [LicenseType.AdditionalUserFee]: 'license.user',
  [LicenseType.AdditionalShopFee]: 'license.shop',
  [LicenseType.AdditionalMemberSiteFee]: 'license.member_site',
  [LicenseType.AdditionalMemberFee]: 'license.member_per_member_site',
};

const useStyles = makeStyles()(() => ({
  wrapperTable: {
    '.MuiTableCell-root': {
      padding: '6px 16px',
    },
  },
}));

const LicenseStatementDialog: FC<ILicenseStatementDialog> = ({ open, license, refetchQueries, onNext, onClose }) => {
  const { t, i18n } = useTranslation();
  const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { selectedOrganization } = useAccount();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [buyLicense] = useBuyLicenseMutation({
    refetchQueries,
  });
  const { data: licenseFeeRes, loading: loadingLicenseFee } = useEstimateFeeLicenseQuery({
    skip: !open,
    fetchPolicy: 'cache-and-network',
    variables: {
      input: license,
    },
  });
  const licenseFee = licenseFeeRes?.estimateFeeLicense;

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      await buyLicense({
        variables: {
          input: license,
        },
      });
      onClose();
      if (!!onNext) {
        onNext();
      }
      enqueueSnackbar(t('toast_message.payment_successful'), { variant: 'success' });
    } catch {
      enqueueSnackbar(t('toast_message.payment_failed'), { variant: 'error' });
    }
    setIsSubmitting(false);
  };

  const remainingDay =
    licenseFee?.numberOfDay +
    (i18n.language === 'ja' ? '日' : ` ${(licenseFee?.numberOfDay || 0) === 1 ? 'day' : 'days'}`);

  return (
    <CustomDialog
      open={open}
      maxWidthPaper={700}
      onClose={onClose}
      dialogTitle={t('license_statement')}
      dialogContent={
        loadingLicenseFee ? (
          <Box height="100px">
            <LoaderCenter />
          </Box>
        ) : (
          <Box padding="0 16px 16px">
            <Stack gap={1} flexDirection="row">
              <Typography flex={1} variant="subtitle1">
                <strong>{t('organization')}:</strong> {selectedOrganization.name}
              </Typography>
              <Typography flex={1} variant="subtitle1">
                <strong>{t('date')}:</strong> {moment().format('YYYY-MM-DD')}
              </Typography>
            </Stack>
            <Divider sx={{ margin: '16px 0' }} />
            <Typography variant="h6">{t('contract_detail')}</Typography>
            <TableContainer className={classes.wrapperTable}>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <TableCell>{t('license_name')}</TableCell>
                    <TableCell>{t('quantity')}</TableCell>
                    <TableCell align="right">{t('unit_price')}</TableCell>
                    <TableCell align="right">{t('subtotal')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{t(licenseNames[license.type])}</TableCell>
                    <TableCell>x{licenseFee?.quantity || '-'}</TableCell>
                    <TableCell align="right">${licenseFee?.price.toFixed(2) || '-'}</TableCell>
                    <TableCell align="right">${licenseFee?.total.toFixed(2) || '-'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell rowSpan={2} colSpan={2} sx={{ borderBottom: 'none' }} />
                    <TableCell align="right" sx={{ borderBottom: 'none' }}>
                      {t('tax')}
                    </TableCell>
                    <TableCell align="right" sx={{ borderBottom: 'none' }}>
                      $0
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="right" sx={{ fontWeight: 700, color: colors.primary, borderBottom: 'none' }}>
                      {t('settings.per_month')}
                    </TableCell>
                    <TableCell align="right" sx={{ fontWeight: 700, color: colors.primary, borderBottom: 'none' }}>
                      ${licenseFee?.total?.toFixed(2) || 0}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Typography variant="body2" textAlign="center" marginTop={2}>
              {t('settings.license_fee_desc')}
            </Typography>
            <Divider sx={{ margin: '16px 0' }} />
            <Typography variant="h6">{t('settings.proration_amount')}</Typography>
            <TableContainer className={classes.wrapperTable}>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <TableCell>{t('license_name')}</TableCell>
                    <TableCell>{t('quantity')}</TableCell>
                    <TableCell align="right">{t('unit_price')}</TableCell>
                    <TableCell align="right">{t('subtotal')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{t(licenseNames[license.type])}</TableCell>
                    <TableCell>x{licenseFee?.quantity || '-'}</TableCell>
                    <TableCell align="right">${licenseFee?.estimatePrice.toFixed(2) || '-'}</TableCell>
                    <TableCell align="right">${licenseFee?.estimateTotal.toFixed(2) || '-'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell rowSpan={2} colSpan={2} sx={{ borderBottom: 'none' }} />
                    <TableCell align="right" sx={{ borderBottom: 'none' }}>
                      {t('tax')}
                    </TableCell>
                    <TableCell align="right" sx={{ borderBottom: 'none' }}>
                      $0
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="right" sx={{ fontWeight: 700, color: colors.primary, borderBottom: 'none' }}>
                      {t('total')}
                    </TableCell>
                    <TableCell align="right" sx={{ fontWeight: 700, color: colors.primary, borderBottom: 'none' }}>
                      ${licenseFee?.estimateTotal?.toFixed(2) || 0}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Stack alignItems="center" marginTop={2}>
              <Typography variant="body1">{t('settings.proration_desc')}</Typography>
              <Box sx={{ gap: '8px', display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1" fontWeight={500} color={colors.primary}>
                  {t('settings.for_number_of_days', {
                    day: remainingDay,
                  })}
                </Typography>
                =
                <Typography variant="h5" fontWeight={700}>
                  ${licenseFee?.estimateTotal?.toFixed(2) || 0}
                </Typography>
              </Box>
            </Stack>
          </Box>
        )
      }
      actions={[
        <Button variant="outlined" disabled={isSubmitting} onClick={onClose}>
          {t('cancel')}
        </Button>,
        <Button
          variant="contained"
          disabled={isSubmitting}
          endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
          onClick={handleSubmit}
        >
          {t('my_shop.purchase')}
        </Button>,
      ]}
    />
  );
};

export default LicenseStatementDialog;
