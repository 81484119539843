import { blue, deepOrange, green, orange, red } from '@mui/material/colors';
import { PaletteColorOptions, createTheme } from '@mui/material/styles';

import { colors } from '~/constants/colors';

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    gray: true;
    black: true;
  }
}

declare module '@mui/material/styles' {
  interface CustomPalette {
    gray: PaletteColorOptions;
    black: PaletteColorOptions;
  }
  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}
}

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor: string) => augmentColor({ color: { main: mainColor } });

export const theme = createTheme({
  colors: {
    white: colors.white,
  },
  palette: {
    gray: createColor(colors.gray),
    black: createColor(colors.black),
    error: {
      main: colors.error,
    },
    primary: {
      main: colors.primary,
    },
  },
});

theme.components = {
  MuiDialogActions: {
    styleOverrides: {
      root: {
        padding: theme.spacing(1, 3, 3, 3),
        '& > :not(:first-of-type)': {
          marginLeft: theme.spacing(2),
        },
      },
      spacing: {
        '& > :not(:first-of-type)': {
          marginLeft: theme.spacing(3),
        },
      },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        p: {
          color: '#444444',
        },
        paddingRight: theme.spacing(7), // Fix title overlap close dialog button
      },
    },
  },
  MuiDataGrid: {
    styleOverrides: {
      columnHeader: {
        outline: 'none !important',
      },
      cell: {
        whiteSpace: 'pre-wrap',
        maxHeight: 'none !important',
        outline: 'none !important',
      },
      row: {
        maxHeight: 'none !important',
      },
      panelWrapper: {
        maxWidth: 'calc(100vw - 34px)',
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        fontWeight: 500,
      },
      colorWarning: {
        color: deepOrange[800],
        backgroundColor: orange[50],
      },
      colorError: {
        color: red[800],
        backgroundColor: red[50],
      },
      colorSuccess: {
        color: green[800],
        backgroundColor: green[50],
      },
      colorInfo: {
        color: blue[800],
        backgroundColor: blue[50],
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        minHeight: '38px',
        lineHeight: '22px',
        padding: '0px 16px',
        alignItems: 'center',
        boxSizing: 'border-box',
      },
    },
  },
  MuiAlert: {
    styleOverrides: {
      root: {
        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: {
          flexDirection: 'row',
        },
      },
    },
  },
};
