import { ChangeEvent, ChangeEventHandler, useEffect, useMemo, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { GridColDef, GridSortModel, jaJP } from '@mui/x-data-grid-pro';
import { jaJP as jaJPDatePicker, DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import * as yup from 'yup';

import CustomCardTable from '~/components/custom-card-table';
import CustomGridToolbarSearchByAPI from '~/components/custom-grid-toolbar-search-by-api';
import CustomDataGrid from '~/components/CustomDataGrid';
import ConfirmationDialog from '~/components/dialog/confirmation-dialog';
import CustomDialog from '~/components/dialog/custom-dialog';
import CustomNodata from '~/components/no-data';
import { NumberStringTextField } from '~/components/NumberStringTextField';
import { SCREEN_PERMISSION } from '~/config/roleConfig';
import {
  GetInfoUsageDocument,
  GetLicensesDocument,
  LicenseType,
  useGetLicensesQuery,
  useRemoveLicenseMutation,
  useUpdateLicenseItemMutation,
} from '~/graphql/member/types';
import useDebounce from '~/hooks/useDebounce';
import { useNotify } from '~/hooks/useNotify';
import { useCheckPermissions } from '~/hooks/with-account';
import i18n from '~/i18n';
import { convertToDayjs } from '~/utils/common';
import { isDateInRange } from '~/utils/isDateInRange';

const { EDIT, DELETE } = SCREEN_PERMISSION.SETTING.PLAN_MANAGEMENT;

const useStyles = makeStyles()(() => ({
  wrapper: {
    width: '100%',
    '.MuiDataGrid-root': {
      border: 'none',
    },
    '.MuiDataGrid-toolbarContainer': {
      padding: '0',
      margin: '0',
    },
    '.actions': {
      gap: '8px',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    '.wrapperDatePicker': {
      gap: '8px',
      display: 'flex',
      marginBottom: '8px',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
  },
}));

interface ILicense {
  id: string;
  uuid: string;
  price: number;
  total: number;
  endedAt: Date;
  status: string;
  createdAt: Date;
  quantity: number;
  type: LicenseType;
}

interface IFilterRange {
  start: Date | null;
  end: Date | null;
}

const licenseNames = {
  [LicenseType.AdditionalUserFee]: 'license.user',
  [LicenseType.AdditionalShopFee]: 'license.shop',
  [LicenseType.AdditionalMemberSiteFee]: 'license.member_site',
  [LicenseType.AdditionalMemberFee]: 'license.member_per_member_site',
};

const status = {
  canceled: 'canceled',
  active: 'settings.plan_management.active',
  unpaid: 'settings.plan_management.unpaid',
  past_due: 'settings.plan_management.past_due',
  incomplete: 'settings.plan_management.incomplete',
};

const schema = yup.object({
  quantity: yup.string().required(),
});

interface FormValues extends yup.InferType<typeof schema> {}

const LicenseStatements = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { showError } = useNotify();
  const { enqueueSnackbar } = useSnackbar();
  const [editable, deletable] = useCheckPermissions([EDIT, DELETE]);

  const [search, setSearch] = useState('');
  const [filterRange, setFilterRange] = useState<IFilterRange>({
    start: null,
    end: null,
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState<ILicense>();
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);

  const { data: licensesRes, loading: loadingLicenses } = useGetLicensesQuery({
    fetchPolicy: 'cache-and-network',
  });
  const [updateLicenseItem] = useUpdateLicenseItemMutation({
    refetchQueries: [GetInfoUsageDocument, GetLicensesDocument],
  });
  const [cancelLicenseItem] = useRemoveLicenseMutation({
    refetchQueries: [GetInfoUsageDocument, GetLicensesDocument],
  });

  const {
    control,
    reset,
    formState: { errors, dirtyFields, isSubmitting },
    handleSubmit,
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
  });

  const isDirty = !!Object.keys(dirtyFields).length;

  const debounceValue = useDebounce(search, 1000);
  const rows: ILicense[] = useMemo(() => {
    return (
      licensesRes?.getLicenses
        .filter((i) => {
          const inRange = isDateInRange(i.createdAt, filterRange.start, filterRange.end);
          const type = t(licenseNames[i.type as LicenseType]).toLowerCase() || '';
          const isIncludes = type.includes(debounceValue.toLowerCase() || '');
          return inRange && isIncludes;
        })
        .map((item) => {
          return {
            type: item.type,
            uuid: item.uuid,
            price: item.price,
            status: item.status,
            id: item.subscriptionId,
            quantity: item.quantity,
            createdAt: item.createdAt,
            total: item.price * item.quantity,
            endedAt: moment().endOf('month').toDate(),
          };
        }) || []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterRange, debounceValue, licensesRes?.getLicenses, i18n.language]);

  // Handle Select Type
  const selectType = (item: ILicense) => {
    setSelectedItem(item);
  };
  const clearSelectedItem = () => {
    setSelectedItem(undefined);
  };

  // Handle Open Dialog
  const handleOpenDialog = (row?: ILicense) => {
    reset({ quantity: row?.quantity.toString() });
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
    clearSelectedItem();
    reset({ quantity: '' });
  };

  // Handle Open Cancel Dialog
  const handleOpenCancelDialog = () => {
    setOpenCancelDialog(true);
  };
  const handleCloseCancelDialog = () => {
    setOpenCancelDialog(false);
  };

  const handleClickAction = (row: ILicense, callback: (row?: ILicense) => void) => () => {
    selectType(row);
    callback(row);
  };

  const columns: GridColDef<ILicense>[] = useMemo(
    () => [
      {
        width: 300,
        field: 'type',
        headerName: t('type'),
        valueFormatter: ({ value }) => t(licenseNames[value as LicenseType]),
      },
      {
        width: 100,
        field: 'status',
        headerName: t('status'),
        valueFormatter: ({ value }) => t(status[value as keyof typeof status]),
      },
      {
        width: 100,
        field: 'price',
        headerName: t('unit_price'),
        valueFormatter: ({ value }) => `$${value}`,
      },
      {
        width: 100,
        field: 'quantity',
        headerName: t('quantity'),
      },
      {
        width: 100,
        field: 'total',
        headerName: t('total'),
        valueFormatter: ({ value }) => `$${value}`,
      },
      {
        width: 100,
        field: 'endedAt',
        headerName: t('ended_at'),
        valueFormatter: ({ value }) => moment(value).format(t('date_format')),
      },
      {
        width: 100,
        field: 'createdAt',
        headerName: t('created_at'),
        valueFormatter: ({ value }) => moment(value).format(t('date_format')),
      },
      {
        width: 200,
        headerName: '',
        sortable: false,
        resizable: false,
        disableReorder: true,
        field: t('information'),
        renderCell: ({ row }) => (
          <Box className="actions">
            {editable && (
              <Button variant="contained" color="primary" onClick={handleClickAction(row, handleOpenDialog)}>
                {t('edit')}
              </Button>
            )}
            {deletable && (
              <Button variant="contained" color="error" onClick={handleClickAction(row, handleOpenCancelDialog)}>
                {t('cancel')}
              </Button>
            )}
          </Box>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, selectedItem]
  );

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleSortModelChange = (model: GridSortModel) => {
    setSortModel(model);
    localStorage.setItem('licenseStatements', JSON.stringify(model));
  };

  const onSubmit = async (data: FormValues) => {
    try {
      if (!selectedItem) return;
      if (data.quantity === '0') {
        handleOpenCancelDialog();
        return;
      } else {
        await updateLicenseItem({
          variables: {
            input: {
              uuid: selectedItem.uuid,
              quantity: parseInt(data.quantity),
            },
          },
        });
        handleCloseDialog();
      }
      enqueueSnackbar(t('my_shop.message.update_successful'), { variant: 'success' });
    } catch (err) {
      showError(err);
    }
  };

  const handleChange =
    (
      onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    ): ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> =>
    (event) => {
      if (!selectedItem) {
        return;
      }
      const value = event.target.value;
      if (parseInt(value) > selectedItem.quantity) {
        return;
      }
      onChange(event);
    };

  const toolbarProps = {
    search,
    searchLabel: t('type'),
    handleSearch,
  };

  const handleCancel = async () => {
    try {
      if (!selectedItem) {
        return;
      }
      await cancelLicenseItem({
        variables: {
          uuid: selectedItem.uuid,
        },
      });
      handleCloseDialog();
    } catch (err) {
      showError(err);
      throw Error();
    }
  };

  const handleChangeFilterDate =
    (isStart = false) =>
    (newDate: Dayjs | null) => {
      const start = !!newDate ? newDate.toDate() : null;
      const end = !!newDate ? newDate.set('h', 23).set('m', 59).set('s', 59).toDate() : null;
      const value = isStart ? { start } : { end };
      setFilterRange((preState) => ({ ...preState, ...value }));
    };

  useEffect(() => {
    const storedSortModel = localStorage.getItem('licenseStatements');
    if (storedSortModel) {
      setSortModel(JSON.parse(storedSortModel));
    }
  }, []);

  return (
    <Box className={classes.wrapper}>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={i18n.language}
        localeText={jaJPDatePicker.components.MuiLocalizationProvider.defaultProps.localeText}
      >
        <Box className="wrapperDatePicker">
          <DatePicker
            label={t('start')}
            slotProps={{ field: { clearable: true } }}
            value={convertToDayjs(filterRange.start)}
            maxDate={convertToDayjs(filterRange.end) || dayjs()}
            onChange={handleChangeFilterDate(true)}
          />
          <DatePicker
            label={t('end')}
            slotProps={{ field: { clearable: true } }}
            value={convertToDayjs(filterRange.end)}
            maxDate={dayjs().set('h', 23).set('m', 59).set('s', 59)}
            onChange={handleChangeFilterDate()}
          />
        </Box>
      </LocalizationProvider>
      <CustomCardTable
        cardTitle={t('license_statements')}
        cardContent={
          <CustomDataGrid
            autoHeight
            rows={rows}
            columns={columns}
            sortModel={sortModel}
            disableVirtualization
            loading={loadingLicenses}
            disableRowSelectionOnClick
            onSortModelChange={handleSortModelChange}
            slotProps={{
              toolbar: toolbarProps,
              noRowsOverlay: {
                message: t('no_data_available'),
              },
            }}
            localeText={i18n.language === 'ja' ? jaJP.components.MuiDataGrid.defaultProps.localeText : undefined}
            slots={{
              toolbar: CustomGridToolbarSearchByAPI,
              noRowsOverlay: CustomNodata,
              noResultsOverlay: CustomNodata,
            }}
          />
        }
      />
      <ConfirmationDialog
        onlyConfirm
        open={openCancelDialog}
        confirmTitle={t('cancel')}
        title={t('settings.cancel_license')}
        content={t('settings.message.confirm_cancel_license')}
        onConfirm={handleCancel}
        onClose={handleCloseCancelDialog}
      />
      <CustomDialog
        open={openDialog}
        dialogTitle={t('edit_quantity')}
        dialogContent={
          <>
            <Controller
              name="quantity"
              control={control}
              render={({ field: { onChange, ...field } }) => (
                <NumberStringTextField
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  label={t('quantity')}
                  error={!!errors.quantity?.message}
                  disabled={isSubmitting}
                  helperText={t(errors.quantity?.message as any)}
                  onChange={handleChange(onChange)}
                  {...field}
                />
              )}
            />
            <Typography variant="caption">
              <Trans i18nKey="max_quantity" values={{ quantity: selectedItem?.quantity || 0 }} />
            </Typography>
          </>
        }
        actions={[
          <Button variant="outlined" disabled={isSubmitting} onClick={handleCloseDialog}>
            {t('cancel')}
          </Button>,
          <Button
            variant="contained"
            disabled={isSubmitting || !isDirty}
            endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
            onClick={handleSubmit(onSubmit)}
          >
            {t('save')}
          </Button>,
        ]}
        onClose={handleCloseDialog}
      />
    </Box>
  );
};

export default LicenseStatements;
