import React, { ReactNode } from 'react';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { makeStyles } from 'tss-react/mui';

interface CardProps {
  reverse?: boolean;
  cardTitle?: ReactNode;
  headerAction?: ReactNode;
  cardContent?: ReactNode;
}

const useStyles = makeStyles<{ reverse?: boolean }>()(({ breakpoints }, { reverse }) => ({
  wrapper: {
    '.MuiPaper-root': {
      borderRadius: '8px',
      boxShadow: 'none',
      border: '1px solid #D7D7D7',
    },
    '& > .MuiCard-root > .MuiCardHeader-root': {
      columnGap: '8px',
      minHeight: '52px',
      padding: '0px 16px',
      borderTop: reverse ? '1px solid #D7D7D7' : '',
      borderBottom: reverse ? '' : '1px solid #D7D7D7',
      [breakpoints.down('md')]: {
        flexWrap: 'wrap',
        padding: '8px 16px',
      },
      '.MuiCardHeader-action': {
        alignSelf: 'center',
      },
    },
    '.MuiCardContent-root': {
      padding: reverse ? 0 : '',
      paddingBottom: '0',
      '&:last-child': {
        paddingBottom: '0',
      },
    },
  },
}));

const CustomCardTable: React.FC<CardProps> = (props) => {
  const { reverse, cardTitle, headerAction, cardContent } = props;
  const { classes } = useStyles({ reverse });

  return (
    <Box className={classes.wrapper}>
      <Card>
        {reverse ? (
          <>
            <CardContent>{cardContent}</CardContent>
            <CardHeader
              title={cardTitle}
              titleTypographyProps={{
                fontSize: '16px',
                fontWeight: 700,
                letterSpacing: '0.1px',
                color: '#111',
              }}
              action={headerAction}
            />
          </>
        ) : (
          <>
            <CardHeader
              title={cardTitle}
              titleTypographyProps={{
                fontSize: '16px',
                fontWeight: 700,
                letterSpacing: '0.1px',
                color: '#111',
              }}
              action={headerAction}
            />
            <CardContent>{cardContent}</CardContent>
          </>
        )}
      </Card>
    </Box>
  );
};

export default CustomCardTable;
