import moment from 'moment';

export const isDateInRange = (dateToCheck: Date, startDate: Date | null, endDate: Date | null) => {
  const date = moment(dateToCheck);
  const start = startDate ? moment(startDate) : undefined;
  const end = endDate ? moment(endDate) : undefined;

  if (start && end) {
    return date.isBetween(start, end, 'day', '[]');
  } else if (start && !end) {
    return date.isSameOrAfter(start, 'day');
  } else if (!start && end) {
    return date.isSameOrBefore(end, 'day');
  } else {
    return true;
  }
};
