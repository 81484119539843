import { memo, useCallback, useEffect, useMemo, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import {
  GridColDef,
  GridColumnResizeParams,
  GridInputRowSelectionModel,
  GridRowSelectionModel,
} from '@mui/x-data-grid';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { makeStyles } from 'tss-react/mui';

import ListTable, { ListTablePagination } from '~/components/list-table';
import SquareImage from '~/components/SquareImage';
import { ITEMS_PER_PAGE } from '~/constants/common';
import { useShopDetail } from '~/contexts/ShopDetailWrapper';
import { useSupportedNetworks } from '~/contexts/SupportedNetworksProvider';
import { VIEW_MODE } from '~/enum/common';
import { env } from '~/env';
import {
  CollectionQueryKey,
  GetMyShopDocument,
  ListCollectionsQuery,
  ListMyShopCollectionsDocument,
  MyShopCollectionQueryKey,
  QueryOperator,
  useAttachMyShopCollectionsMutation,
  useListCollectionIdsInShopQuery,
  useListCollectionsLazyQuery,
  useListCollectionsQuery,
  useUnAttachMyShopCollectionsMutation,
} from '~/graphql/member/types';
import { CollectionData } from '~/pages/collection';
import { COLLECTION_TYPE } from '~/types/my-shop';
import { getLocalStorage, setLocalStorageItems, verifyOrderKey, verifySortKey } from '~/utils/common';
import { truncateEthAddress } from '~/utils/string.utils';

interface AddCollectionDialogProps {
  open: boolean;
  onClose: () => void;
  refetchMyShopCollections: () => void;
}

export interface ShopCollection {
  type?: string;
  collectionUuid: string;
}

const useStyles = makeStyles()(() => ({
  dialog: {
    '.MuiPaper-root': {
      maxWidth: 1180,
      width: '100%',
      position: 'relative',
    },
    '.guide-text': {
      marginLeft: '24px',
      color: '#333',
    },
    '.MuiDialogTitle-root': {
      paddingBottom: '0',
      '.MuiTypography-h2': {
        fontSize: '24px',
        fontWeight: 400,
        lineHeight: '32px',
      },
      '.MuiTypography-caption': {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '28px',
      },
    },
    '.MuiDialogContent-root': {
      padding: '0 24px',
    },
    '.MuiDialogActions-root': {
      padding: '16px 24px',
    },
  },
  layer: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 999,
    opacity: 0.3,
    width: '100%',
    height: '100%',
    position: 'absolute',
    backgroundColor: 'white',
  },
  table: {
    '.MuiOutlinedInput-root .MuiSelect-outlined': {
      paddingTop: '14px',
      paddingBottom: '14px',
    },
  },
}));

const AddCollectionDialog = (props: AddCollectionDialogProps) => {
  const { open, onClose, refetchMyShopCollections } = props;

  const { data: memberSite } = useShopDetail();
  const { supportedNetworks } = useSupportedNetworks();

  const { id } = useParams();
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [getListCollections] = useListCollectionsLazyQuery();

  const [collectionsQuery, setCollectionsQuery] = useState({
    page: 1,
    limit: ITEMS_PER_PAGE.LIST,
    searchText: '',
    sortBy: verifySortKey(CollectionQueryKey, getLocalStorage('mb_attach_collection_list_sort')),
    orderBy: verifyOrderKey(getLocalStorage('mb_attach_collection_list_order')),
    where: { availableShopUuid: memberSite.uuid ?? '' },
  });

  const updateCollectionsQuery = (newValue: any) => setCollectionsQuery((value: any) => ({ ...value, ...newValue }));

  useEffect(() => {
    if (!open)
      setCollectionsQuery({
        page: 1,
        limit: ITEMS_PER_PAGE.LIST,
        searchText: '',
        sortBy: verifySortKey(CollectionQueryKey, getLocalStorage('mb_attach_collection_list_sort')),
        orderBy: verifyOrderKey(getLocalStorage('mb_attach_collection_list_order')),
        where: { availableShopUuid: memberSite.uuid ?? '' },
      });
  }, [memberSite.uuid, open]);

  useEffect(() => {
    setLocalStorageItems({
      mb_attach_collection_list_sort: collectionsQuery?.sortBy,
      mb_attach_collection_list_order: collectionsQuery?.orderBy,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionsQuery?.orderBy, collectionsQuery?.sortBy]);

  const { data: dataListCollections, loading: loadingListCollections } = useListCollectionsQuery({
    fetchPolicy: 'cache-and-network',
    variables: collectionsQuery,
  });

  const { data: dataListShopCollections, loading: loadingListShopCollections } = useListCollectionIdsInShopQuery({
    fetchPolicy: 'cache-and-network',
    skip: !open,
    variables: {
      where: {
        fields: [
          {
            value: [id ?? ''],
            operator: QueryOperator.Contains,
            key: MyShopCollectionQueryKey.ShopUuid,
          },
        ],
      },
    },
  });

  const { items, pagination } = useMemo(() => {
    const items = dataListCollections?.listCollections?.items || [];
    const pagination: ListTablePagination = dataListCollections?.listCollections?.pagination || {};

    const _items: CollectionData[] = items.map((item) => ({
      id: item.uuid,
      name: item.name,
      symbol: item.symbol,
      network: item.network,
      createdAt: item.createdAt,
      type: item.type as COLLECTION_TYPE,
      contractAddress: item.contractAddress,
      ownerAddress: item.ownerAddress || undefined,
      url: env.REACT_APP_API_MEDIA + '/' + item?.images?.[0],

      //new key
      [CollectionQueryKey.Name]: item.name,
      [CollectionQueryKey.Symbol]: item.symbol,
      [CollectionQueryKey.CreatedAt]: item.createdAt,
      [CollectionQueryKey.OwnerAddress]: item.ownerAddress || undefined,
      [CollectionQueryKey.ContractAddress]: item.contractAddress,
      [CollectionQueryKey.Network]: item.network,
    }));

    return { items: _items, pagination };
  }, [dataListCollections]);

  const { items: selectedCollections } = useMemo(() => {
    const items = dataListShopCollections?.listMyShopCollections?.items || [];
    return { items };
  }, [dataListShopCollections]);

  const loadingData = loadingListCollections || loadingListShopCollections;

  const [attachCollectionToShop] = useAttachMyShopCollectionsMutation({
    refetchQueries: [GetMyShopDocument, ListMyShopCollectionsDocument],
    onCompleted: () => updateCollectionsQuery({ page: 1 }),
  });
  const [unAttachCollectionShop] = useUnAttachMyShopCollectionsMutation({
    refetchQueries: [GetMyShopDocument, ListMyShopCollectionsDocument],
    onCompleted: () => updateCollectionsQuery({ page: 1 }),
  });

  const [isAttachingCollection, setIsAttachingCollection] = useState(false);
  const [selectedCollectionIds, setSelectedCollectionIds] = useState<GridRowSelectionModel | undefined>([]);
  const [tempSelectedCollectionIds, setTempSelectedCollectionIds] = useState<GridRowSelectionModel | undefined>([]);

  const onColumnWidthChange = (data: GridColumnResizeParams) => {
    const columnsSize = localStorage.getItem('columnsSize');
    if (columnsSize) {
      const parsed = JSON.parse(columnsSize);
      if (parsed) {
        parsed[data.colDef.field] = data.width;
        localStorage.setItem('columnsSize', JSON.stringify(parsed));
      }
    } else {
      localStorage.setItem('columnsSize', JSON.stringify({ [data.colDef.field]: data.width }));
    }
  };

  const columns: GridColDef<CollectionData>[] = useMemo(() => {
    const columnsSize = localStorage.getItem('columnsSize') || '{}';
    return [
      {
        width: 84,
        field: 'url',
        sortable: false,
        resizable: false,
        headerName: t('image'),
        renderCell: ({ value }) => {
          return (
            <Box width="64px">
              <SquareImage src={value} />
            </Box>
          );
        },
      },
      {
        field: CollectionQueryKey.Name,
        headerName: t('my_shop.collection_name'),
        width: JSON.parse(columnsSize).name || 150,
      },
      {
        field: CollectionQueryKey.Symbol,
        headerName: t('symbol'),
        width: JSON.parse(columnsSize).symbol || 150,
      },
      {
        field: CollectionQueryKey.ContractAddress,
        headerName: t('contract_address'),
        valueFormatter: ({ value }) => truncateEthAddress(value),
        width: JSON.parse(columnsSize).contractAddress || 150,
      },
      {
        field: CollectionQueryKey.Network,
        headerName: t('network'),
        valueFormatter: ({ value }) => (value ? supportedNetworks?.[value]?.name : '-'),
        width: JSON.parse(columnsSize).network || 150,
      },
      {
        field: CollectionQueryKey.OwnerAddress,
        headerName: t('owner_address'),
        width: JSON.parse(columnsSize).ownerAddress || 150,
        valueFormatter: ({ value }) => (value ? truncateEthAddress(value) : '-'),
      },
      {
        type: 'date',
        headerName: t('created_at'),
        getApplyQuickFilterFn: undefined,
        field: CollectionQueryKey.CreatedAt,
        width: JSON.parse(columnsSize).createdAt || 115,
        valueFormatter: ({ value }) => (value ? moment(value).format(t('date_format')) : '-'),
      },
    ];
  }, [supportedNetworks, t]);

  const onAttachCollection = useCallback(async () => {
    try {
      if (!isAttachingCollection) {
        return;
      }
      const attachedCollectionIds = tempSelectedCollectionIds?.filter((id) => !selectedCollectionIds?.includes(id));
      const unattachedCollectionIds = selectedCollectionIds?.filter((id) => !tempSelectedCollectionIds?.includes(id));

      let errorsList: string[] = [];
      let attachableCollections: ShopCollection[] = [];

      if (attachedCollectionIds && attachedCollectionIds.length > 0) {
        const {
          data: {
            listCollections: { items: attachedCollections } = {
              items: [] as ListCollectionsQuery['listCollections']['items'],
            },
          } = {},
        } = await getListCollections({
          fetchPolicy: 'cache-and-network',
          variables: {
            where: {
              fields: [
                {
                  value: attachedCollectionIds as string[],
                  operator: QueryOperator.Anyof,
                  key: CollectionQueryKey.Uuid,
                },
              ],
            },
          },
        });
        attachableCollections =
          attachedCollections?.reduce((result, item) => {
            if (attachedCollectionIds?.includes(item.uuid)) {
              const newCollection: ShopCollection = {
                collectionUuid: item.uuid,
              };
              result.push(newCollection);
            }
            return result;
          }, [] as ShopCollection[]) || [];
        const attachedCollectionsRes = await attachCollectionToShop({
          variables: {
            input: {
              shopUuid: memberSite?.uuid,
              collections: attachableCollections || [],
            },
          },
        });
        errorsList =
          attachedCollectionsRes?.data?.attachMyShopCollections?.filter((myShopCollection) => !myShopCollection) || [];
      }

      if (unattachedCollectionIds && unattachedCollectionIds.length > 0) {
        await unAttachCollectionShop({
          variables: {
            input: {
              shopUuid: memberSite?.uuid,
              collectionUuids: unattachedCollectionIds as string[],
            },
          },
        });
      }

      onClose();
      await refetchMyShopCollections();
      if (errorsList.length > 0) {
        enqueueSnackbar(t('form_validation.attached_collection_member_site'), { variant: 'error' });
      }
      if (errorsList.length !== attachableCollections.length) {
        enqueueSnackbar(t('my_shop.message.update_successful'), { variant: 'success' });
      }
      setIsAttachingCollection(false);
    } catch (error: any) {
      setIsAttachingCollection(false);
      enqueueSnackbar(error?.error?.message || error.message || t('my_shop.message.error'), { variant: 'error' });
    }
  }, [
    memberSite.uuid,
    isAttachingCollection,
    tempSelectedCollectionIds,
    selectedCollectionIds,
    onClose,
    refetchMyShopCollections,
    enqueueSnackbar,
    t,
    getListCollections,
    attachCollectionToShop,
    unAttachCollectionShop,
  ]);

  const checkConnectionStatus = () => {
    setIsAttachingCollection(true);
  };

  const handleRowSelectionChange = (rowSelectionModel: GridRowSelectionModel) => {
    setTempSelectedCollectionIds(rowSelectionModel);
  };

  useEffect(() => {
    if (isAttachingCollection) {
      onAttachCollection();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAttachingCollection]);

  useEffect(() => {
    const selectedIds = selectedCollections?.reduce((output, current) => {
      const newId = current.collectionUuid;
      if (newId) {
        output.push(newId);
      }
      return output;
    }, [] as string[]);
    setSelectedCollectionIds(selectedIds);
    setTempSelectedCollectionIds(selectedIds);
  }, [selectedCollections]);

  return (
    <Dialog open={open} onClose={onClose} scroll="body" className={classes.dialog}>
      <DialogTitle component="div">
        <Typography variant="h2">{t('my_shop.attach_new_collection')}</Typography>
        <Typography variant="caption">{t('member_site.attach_collection_description')}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <Divider sx={{ borderColor: '#9E9E9E', width: 'calc(100% - 48px)', margin: '16px auto' }} />
      <DialogContent>
        <Box height="60vh">
          <ListTable
            rows={items}
            checkboxSelection
            columns={columns}
            isLoading={loadingData}
            onlyMode={VIEW_MODE.LIST}
            paginationData={pagination}
            disableRowSelectionOnClick
            keepNonExistentRowsSelected
            search={collectionsQuery.searchText}
            tableName="mb_attach_collection_list"
            searchLabel={t('my_shop.collection_name')}
            noRowsMessage={t('my_shop.message.no_collection')}
            rowSelectionModel={tempSelectedCollectionIds as GridInputRowSelectionModel}
            isRowSelectable={(params) => !supportedNetworks?.[params?.row?.network]?.testMode}
            sort={{
              sortBy: collectionsQuery.sortBy,
              orderBy: collectionsQuery.orderBy,
            }}
            onPagination={updateCollectionsQuery}
            onColumnWidthChange={onColumnWidthChange}
            onRowSelectionModelChange={handleRowSelectionChange}
            onSort={(value = {}) => updateCollectionsQuery(value)}
            onSearch={(v) => updateCollectionsQuery({ page: 1, searchText: v || '' })}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Box>
          <Button variant="outlined" onClick={onClose} sx={{ marginRight: '16px' }}>
            {t('cancel')}
          </Button>
          <Button
            variant="contained"
            onClick={checkConnectionStatus}
            disabled={isAttachingCollection}
            endIcon={isAttachingCollection && <CircularProgress size={20} color="inherit" />}
          >
            {t('my_shop.attach')}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default memo(AddCollectionDialog);
