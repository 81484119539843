import { Fragment, ReactNode } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import { makeStyles } from 'tss-react/mui';

import DialogCloseButton from '../dialog-close-button';

const useStyles = makeStyles<{ maxWidthPaper?: number }>()((_, { maxWidthPaper }) => ({
  initDialog: {
    '.MuiPaper-root': {
      padding: '16px',
      maxWidth: `${maxWidthPaper || 500}px`,
      '& > .MuiDivider-root': {
        margin: '16px 0',
        borderColor: '#9E9E9E',
      },
    },
    '.MuiDialogTitle-root': {
      padding: '0 40px 0 0',
      fontWeight: 400,
      fontSize: '24px',
    },
    '.MuiDialogContent-root': {
      padding: 0,
      marginBottom: '16px',
    },
    '.MuiDialogActions-root': {
      padding: 0,
      '& > :not(:first-of-type)': {
        marginLeft: '16px',
      },
    },
  },
}));

interface Props extends DialogProps {
  dialogTitle: ReactNode;
  maxWidthPaper?: number;
  actions?: JSX.Element[];
  dialogContent: ReactNode;
  hideCloseButton?: boolean;
  hideTitleDivider?: boolean;
  disabledCloseButton?: boolean;
  onClose: () => void;
}

const CustomDialog: React.FC<Props> = (props) => {
  const {
    onClose,
    className,
    maxWidthPaper,
    actions: _,
    dialogTitle: __,
    dialogContent: ___,
    hideTitleDivider: ____,
    ...others
  } = props;

  const { classes, cx } = useStyles({ maxWidthPaper });

  const handleCloseDialog: DialogProps['onClose'] = (_, reason) => {
    if (reason && (reason === 'backdropClick' || reason === 'escapeKeyDown')) {
      return;
    }
    onClose();
  };

  return (
    <Dialog
      {...others}
      fullWidth
      scroll="body"
      className={cx(className, classes.initDialog)}
      onClose={handleCloseDialog}
    >
      <Content {...props} />
    </Dialog>
  );
};

const Content: React.FC<Props> = (props) => {
  const { dialogTitle, dialogContent, hideTitleDivider, hideCloseButton, actions, disabledCloseButton, onClose } =
    props;

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <DialogTitle sx={{ flex: 1 }}>{dialogTitle}</DialogTitle>
        {!hideCloseButton && (
          <DialogCloseButton onClick={onClose} data-testid="close-button" disabled={disabledCloseButton}>
            <CloseIcon />
          </DialogCloseButton>
        )}
      </Box>
      {!hideTitleDivider && <Divider />}
      <DialogContent>{dialogContent}</DialogContent>
      {!!actions?.length && (
        <DialogActions>
          {actions.map((action, idx) => (
            <Fragment key={idx}>{action}</Fragment>
          ))}
        </DialogActions>
      )}
    </>
  );
};

export default CustomDialog;
