import Box from '@mui/material/Box';

import LicenseStatements from './LicenseStatements';
import SummaryInformation from './SummaryInformation';

const PlanManagement = () => {
  return (
    <Box gap="24px" display="flex" flexDirection="column">
      <SummaryInformation />
      <LicenseStatements />
    </Box>
  );
};

export default PlanManagement;
