import React, { useCallback, useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { DialogProps } from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import CustomDialog from '../dialog/custom-dialog';

import countries from '~/data/countries.json';
import { GetMeDocument, useCreateOrganizationMutation } from '~/graphql/member/types';
import { useNotify } from '~/hooks/useNotify';

interface Props extends DialogProps {
  onClose: () => void;
  onSelectedOrganization: (uuid: string, isCreatingOrg?: boolean) => void;
}

const schema = yup.object({
  country: yup.string().max(100).required(),
  name: yup.string().trim().max(100).required(),
  contactEmail: yup.string().trim().email('form_validation.invalid_email').required(),
});

interface FormValues extends yup.InferType<typeof schema> {}

const countryOptions = countries.map((option) => (
  <MenuItem key={option.code} value={option.code}>
    {option.name}
  </MenuItem>
));

const CreateOrganizationDialog: React.FC<Props> = (props) => {
  const { open, onClose, onSelectedOrganization } = props;

  const { t } = useTranslation();
  const { showError } = useNotify();
  const [createOrganization] = useCreateOrganizationMutation({
    refetchQueries: [GetMeDocument],
  });

  const MAX_LENGTH = 100;

  const {
    control,
    formState: { errors, isSubmitting },
    reset,
    setError,
    handleSubmit,
  } = useForm<FormValues>({
    defaultValues: {
      name: '',
      country: 'SG',
      contactEmail: '',
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = useCallback(
    async (data: FormValues) => {
      try {
        const newOrgRes = await createOrganization({
          variables: {
            input: {
              language: 'en-US',
              region: data.country,
              name: data.name.trim(),
              contactEmail: data.contactEmail.trim(),
            },
          },
        });
        const newOrgUuid = newOrgRes.data?.createOrganization.uuid;
        if (newOrgUuid) {
          onSelectedOrganization(newOrgUuid, true);
        }
        onClose();
      } catch (error: any) {
        if (errors.name) {
          setError('name', { message: 'my_shop.message.errors.max' });
        } else if (errors.contactEmail) {
          setError('contactEmail', { message: 'my_shop.message.errors.max' });
        } else {
          showError(error);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errors, setError, createOrganization, onClose]
  );

  useEffect(() => {
    if (open) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      dialogTitle={t('create_organization_dialog.title')}
      dialogContent={
        <>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                margin="dense"
                variant="outlined"
                label={t('organization_name')}
                error={!!errors.name?.message}
                helperText={!!errors.name?.message && t('my_shop.message.errors.max', { max: MAX_LENGTH })}
                {...field}
              />
            )}
          />
          <Controller
            name="country"
            control={control}
            render={({ field }) => (
              <TextField
                select
                fullWidth
                margin="normal"
                variant="outlined"
                error={!!errors.country?.message}
                label={t('my_shop.operation.country')}
                helperText={t(errors.country?.message as any)}
                {...field}
              >
                {countryOptions}
              </TextField>
            )}
          />
          <Controller
            name="contactEmail"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                label={t('contact_email')}
                variant="outlined"
                margin="normal"
                error={!!errors.contactEmail?.message}
                helperText={t(errors.contactEmail?.message as any)}
                {...field}
              />
            )}
          />
        </>
      }
      actions={[
        <Button
          color="primary"
          variant="contained"
          disabled={isSubmitting}
          endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
          onClick={handleSubmit(onSubmit)}
        >
          {t('create')}
        </Button>,
      ]}
    />
  );
};

export default CreateOrganizationDialog;
