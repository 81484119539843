import { FC, useState } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import { Trans, useTranslation } from 'react-i18next';

import PlanInvoiceDialog from '~/components/dialog/plan-invoice-dialog';
import { colors } from '~/constants/colors';
import { GetPlansQuery, OrganizationUserRole } from '~/graphql/member/types';
import { useAccount } from '~/hooks/with-account';

interface IPlanCard {
  isSelected: boolean;
  plan: GetPlansQuery['getPlans'][number];
}

const PlanCard: FC<IPlanCard> = ({ plan, isSelected }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { selectedOrganization } = useAccount();

  const [openInvoice, setOpenInvoice] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const isOwner = selectedOrganization.role === OrganizationUserRole.Owner;

  const infos = [
    {
      title: t('plans.number_of_users'),
      desc: [
        {
          icon: <CheckIcon />,
          color: colors.text.primary,
          key: 'plans.number_of_users_desc',
          value: { amount: plan.numberOfAdminUsers },
        },
        {
          color: '#024FAB',
          icon: <PaidOutlinedIcon />,
          key: 'plans.additional_users',
          value: { price: plan.additionalUserFee + '$' },
        },
      ],
    },
    {
      title: t('plans.number_of_shops'),
      desc: [
        {
          icon: <CheckIcon />,
          color: colors.text.primary,
          key: 'plans.number_of_shops_desc',
          value: { amount: plan.numberOfShops },
        },
        {
          color: '#024FAB',
          icon: <PaidOutlinedIcon />,
          key: 'plans.additional_shops',
          value: { price: plan.additionalShopFee + '$' },
        },
      ],
    },
    {
      title: t('plans.number_of_member_sites'),
      desc: [
        {
          icon: <CheckIcon />,
          color: colors.text.primary,
          key: 'plans.number_of_member_sites_desc',
          value: { amount: plan.numberOfMemberSites },
        },
        {
          color: '#024FAB',
          icon: <PaidOutlinedIcon />,
          key: 'plans.additional_member_sites',
          value: { price: plan.additionalSiteFee + '$' },
        },
      ],
    },
    {
      title: t('plans.number_of_members_per_site'),
      desc: [
        {
          icon: <CheckIcon />,
          color: colors.text.primary,
          key: 'plans.number_of_members_per_site_desc',
          value: { amount: plan.numberOfMemberPerSite },
        },
        {
          color: '#024FAB',
          icon: <PaidOutlinedIcon />,
          key: 'plans.additional_members_per_site',
          value: { price: plan.additionalMemberFee + '$' },
        },
      ],
    },
  ];

  const handleOpenInvoice = () => {
    setOpenInvoice(true);
  };
  const handleCloseInvoice = () => {
    setOpenInvoice(false);
  };

  const verifyLicensesNumber = async () => {};

  const selectPlan = async () => {
    try {
      setIsProcessing(true);
      handleOpenInvoice();
    } catch (err) {}
    setIsProcessing(false);
  };

  return (
    <>
      <Card>
        <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
          <Box>
            <Typography variant="h6">{plan.planName}</Typography>
            <Typography variant="h4">{plan.monthlyFee === 0 ? t('free') : `$${plan.monthlyFee}`}</Typography>
          </Box>
          <Stack gap="16px" height={470} overflow="auto">
            {infos.map((info, idx) => (
              <Stack key={idx} gap="8px">
                <Typography variant="subtitle1" fontWeight={500}>
                  {info.title}
                </Typography>
                {info.desc.map((item, idx) => (
                  <Stack key={idx} alignItems="flex-start" direction="row" gap="4px" color={item.color}>
                    {item.icon}
                    <Typography whiteSpace="pre-wrap">
                      <Trans i18nKey={item.key} values={item.value} />
                    </Typography>
                  </Stack>
                ))}
              </Stack>
            ))}
          </Stack>
        </CardContent>
        <CardActions>
          <Button
            fullWidth
            variant="contained"
            disabled={isSelected || isProcessing || !isOwner}
            endIcon={isProcessing && <CircularProgress size={20} color="inherit" />}
            onClick={selectPlan}
          >
            {t(isProcessing ? 'processing' : isSelected ? 'selected' : 'plans.select_this_plan')}
          </Button>
        </CardActions>
      </Card>
      <PlanInvoiceDialog open={openInvoice} plan={plan} onClose={handleCloseInvoice} />
    </>
  );
};

export default PlanCard;
