import { Fragment, useRef, useState } from 'react';

import RefreshIcon from '@mui/icons-material/Refresh';
import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { TFunction } from 'i18next';
import moment, { Moment } from 'moment';
import 'moment/locale/ja';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import CustomCardTable from '~/components/custom-card-table';
import LoaderCenter from '~/components/loader-center';
import PermissionsView from '~/components/PermissionsView';
import { SCREEN_PERMISSION } from '~/config/roleConfig';
import { colors } from '~/constants/colors';
import { INVOICE_ITEM_NAME } from '~/constants/common';
import { usePaymentMethodRequired } from '~/contexts/PaymentMethodRequired';
import { InvoiceType } from '~/enum/common';
import {
  Currency,
  ListAllOpenInvoicesQuery,
  useGetPlanLazyQuery,
  useListAllOpenInvoicesQuery,
  usePayAllInvoicesLazyQuery,
} from '~/graphql/member/types';
import { CustomInvoiceType } from '~/types/billing';
import { currencySymbol, priceWithSymbol, replaceLicenseName } from '~/utils/common';

const { PAY_NOW } = SCREEN_PERMISSION.SETTING.BILLING;

interface IUnpaidInvoicesInfo {
  tax: number;
  total: number;
  subtotal: number;
  unpaidInvoices: CustomInvoiceType[];
}

type UnpaidInvoicesType = Record<Currency, IUnpaidInvoicesInfo>;

const useStyles = makeStyles()(() => ({
  wrapperTable: {
    marginBottom: '16px',
    '.MuiTableCell-root': {
      fontSize: '15px',
    },
  },
}));

const UnpaidInvoices = () => {
  const { classes } = useStyles();
  const { t, i18n } = useTranslation();
  const { show } = usePaymentMethodRequired();

  const [isLoading, setIsLoading] = useState(false);
  const [updatedDate, setUpdatedDate] = useState<Moment>();

  const infos = useRef({} as UnpaidInvoicesType);

  const handleCompleted = async (data: ListAllOpenInvoicesQuery) => {
    setIsLoading(true);
    setUpdatedDate(moment());

    const unpaidInvoicesByCurrency = {} as UnpaidInvoicesType;
    for (let invoice of data.listAllOpenInvoices) {
      const currency = invoice.currency.toUpperCase() as Currency;

      const metadata = invoice.metadata;
      const type = metadata?.type as InvoiceType;
      const unpaidInvoice = {
        ...invoice,
        name: t(INVOICE_ITEM_NAME[type]),
      };

      if (type === InvoiceType.PurchasePlan && !!metadata?.uuid) {
        const planRes = await getPlan({
          variables: {
            uuid: metadata.uuid,
          },
        });
        const planName = planRes.data?.getPlan.planName;
        if (!!planName) {
          moment.locale(i18n.language);
          const nextMonth = moment(invoice.createdAt).format('MMMM');

          unpaidInvoice.name = t('settings.billing.renew_plan_desc', { planName, month: nextMonth });

          moment.locale('en');
        }
      }

      if (!unpaidInvoicesByCurrency[currency]) {
        unpaidInvoicesByCurrency[currency] = {
          unpaidInvoices: [unpaidInvoice],
          tax: invoice.tax,
          total: invoice.total,
          subtotal: invoice.subtotal,
        };
      } else {
        unpaidInvoicesByCurrency[currency].unpaidInvoices.push(unpaidInvoice);
        unpaidInvoicesByCurrency[currency].tax += invoice.tax;
        unpaidInvoicesByCurrency[currency].total += invoice.total;
        unpaidInvoicesByCurrency[currency].subtotal += invoice.subtotal;
      }
    }

    infos.current = unpaidInvoicesByCurrency;
    setIsLoading(false);
  };

  const [getPlan] = useGetPlanLazyQuery({
    fetchPolicy: 'cache-and-network',
  });

  const {
    data: listAllOpenInvoicesRes,
    loading: loadingListAllOpenInvoices,
    refetch: refetchListAllOpenInvoices,
  } = useListAllOpenInvoicesQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: handleCompleted,
  });

  const [payAllInvoices, { loading: isSubmitting }] = usePayAllInvoicesLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data.payAllInvoices) {
        refetchListAllOpenInvoices();
      }
    },
  });

  const handleReload = () => {
    refetchListAllOpenInvoices();
  };

  const handlePayNow = async () => {
    const notRegistered = await show({
      description: '',
    });
    if (notRegistered) {
      return;
    }
    await payAllInvoices();
  };

  if (!listAllOpenInvoicesRes?.listAllOpenInvoices.length) {
    return null;
  }

  const renderInvoices = (invoices: CustomInvoiceType[], currency: Currency) =>
    invoices.map((invoice, idx) => {
      const invoiceItemsLength = invoice.items.length;
      const invoiceItem = invoice.items[0];
      const unitPriceMain = (invoice.total || 0) / (invoiceItem.quantity || 0);
      return invoiceItemsLength > 1 ? (
        <Fragment key={idx}>
          <TableRow>
            <TableCell />
            <TableCell colSpan={4}>{invoice.name}</TableCell>
            <TableCell align="right" sx={{ fontWeight: 700 }}>
              {priceWithSymbol(invoice.total, currency, 2)}
            </TableCell>
          </TableRow>
          {invoice.items.map((item, idx) => {
            const unitPriceSub = (item.amountExcludingTax || 0) / (item.quantity || 0);
            return (
              <TableRow key={idx}>
                <TableCell />
                <TableCell />
                <TableCell>{replaceLicenseName(item.description, t)}</TableCell>
                <TableCell align="right">{priceWithSymbol(unitPriceSub, currency, 2)}</TableCell>
                <TableCell align="right">{item.quantity}</TableCell>
                <TableCell align="right">{priceWithSymbol(item.amountExcludingTax, currency, 2)}</TableCell>
              </TableRow>
            );
          })}
        </Fragment>
      ) : (
        <TableRow key={idx}>
          <TableCell />
          <TableCell colSpan={2}>{invoice.name}</TableCell>
          <TableCell align="right">{priceWithSymbol(unitPriceMain, currency, 2)}</TableCell>
          <TableCell align="right">{invoiceItem.quantity}</TableCell>
          <TableCell align="right" sx={{ fontWeight: 700 }}>
            {priceWithSymbol(invoice.total, currency, 2)}
          </TableCell>
        </TableRow>
      );
    });

  const renderInvoiceByCurrency = () =>
    (Object.keys(infos.current) as Currency[]).map((currency, idx, arr) => {
      const isLast = arr.length - 1 === idx;
      const info = infos.current[currency];
      return (
        <Fragment key={currency}>
          <TableRow>
            <TableCell colSpan={6}>{`${currency} (${currencySymbol(currency)})`}</TableCell>
          </TableRow>
          {renderInvoices(info.unpaidInvoices, currency)}
          <TableRow>
            <TableCell rowSpan={isLast ? 4 : 3} colSpan={3} sx={isLast ? { borderBottom: 'none' } : {}} />
            <TableCell colSpan={2} sx={{ fontWeight: 700 }}>
              {t('subtotal')}
            </TableCell>
            <TableCell align="right" sx={{ fontWeight: 700 }}>
              {priceWithSymbol(info.subtotal, currency, 2)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={2}>{t('tax')}</TableCell>
            <TableCell align="right">{priceWithSymbol(info.tax, currency, 2)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={2} sx={{ fontWeight: 700, color: colors.primary }}>
              {t('total')}
            </TableCell>
            <TableCell align="right" sx={{ fontWeight: 700, color: colors.primary }}>
              {priceWithSymbol(info.total, currency, 2)}
            </TableCell>
          </TableRow>
        </Fragment>
      );
    });

  return (
    <CustomCardTable
      cardTitle={t('settings.billing.unpaid_invoices')}
      headerAction={
        <Box display="flex" alignItems="center">
          <Typography marginRight="8px">
            {t('my_shop.last_published_date')}:{' '}
            {updatedDate ? moment(updatedDate).format(t('date_time_format')) : '...'}
          </Typography>
          <IconButton size="medium" onClick={handleReload}>
            <RefreshIcon fontSize="inherit" />
          </IconButton>
        </Box>
      }
      cardContent={
        loadingListAllOpenInvoices || isLoading ? (
          <Box height="200px">
            <LoaderCenter />
          </Box>
        ) : (
          <Box className={classes.wrapperTable}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width={150}>{t('currency_unit')}</TableCell>
                    <TableCell colSpan={2}>{t('description')}</TableCell>
                    <TableCell align="right">{t('unit')}</TableCell>
                    <TableCell align="right">{t('quantity')}</TableCell>
                    <TableCell align="right">{t('settings.amount')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {renderInvoiceByCurrency()}
                  <PermissionsView roles={PAY_NOW}>
                    <TableRow>
                      <TableCell colSpan={3} align="center" sx={{ borderBottom: 'none' }}>
                        <Button
                          fullWidth
                          variant="contained"
                          disabled={isSubmitting}
                          endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
                          onClick={handlePayNow}
                        >
                          {t('my_shop.pay_now')}
                        </Button>
                      </TableCell>
                    </TableRow>
                  </PermissionsView>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )
      }
    />
  );
};

export default UnpaidInvoices;
